<div class="container">
    <div class="main">
        <div class="row">
            <div class="col">
                <div class="main-heading">
                    <h3>TRACKING INFORMATION</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="product">
                
            </div>
        </div>
    </div>
            <div class="row">
        <div class="col">
            <div class="panel">	
                <div class="panel-heading">
                    <h3 class="panel-title">TRACKING DETAILS</h3>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <h5 class="panel-mid-heading"><strong>Consignee:</strong> {{ data.Consignee }}</h5>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <h5 class="panel-mid-heading"><strong>AWB Number / Order Number:</strong> {{ data.Awb }} / {{ data.OrderNo }}</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <h5 class="panel-mid-heading"><strong>Product Type:</strong> {{ data.Product }}</h5>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <h5 class="panel-mid-heading"><strong>Expected DOD:</strong> {{ data.ExpectedDOD }} </h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <h5 class="panel-mid-heading"><strong>Pincode:</strong> {{ data.DestPin }}</h5>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <h5 class="panel-mid-heading"><strong>Address:</strong> {{ data.Address }} </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-box">
                <div class="row">
                    <div class="col">
                        <div class="content">
                        <table class="table table-bordered table-hover">
                        <thead>
                            <tr class="bg-light">
                                <th>
                                    <strong>City</strong>
                                </th>
                                <th>
                                    <strong>Status</strong>
                                </th>
                                <th>
                                    <strong> Date </strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let event of events">
                                <td>
                                    {{ event.City }}
                                </td>
                                <td>
                                    {{ event.Message }} 
                                </td>
                                <td>
                                    {{ event.CreatedAt }} 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    
</div>