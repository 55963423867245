import { Injectable, OnInit } from '@angular/core';
import { AuthService } from '../modules/auth';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserWiseAccessService  implements OnInit{

  public UserType: string;
  role;
  public userId;
  data;
  public selectParam;
  public _restrictedPermission$: BehaviorSubject<boolean>;
  public _subscriptions: Subscription[] = [];

  get restrictedPermission$() {
    return this._restrictedPermission$.asObservable();
  }

  get subscriptions() {
    return this._subscriptions;
  }

  constructor(private auth: AuthService,private router: Router) 
  { 
    this._restrictedPermission$ = new BehaviorSubject(false);
    this.role = this.auth.getAuthFromLocalStorage();
    this.UserType = this.role.roleName;
    this.auth.currentUserSubject.subscribe((d:any)=> {   
      if(d != null)    
        this.userId = d.UserId; })   
  }

  ngOnInit(): void {
    this.userParam();
    // console.log("role name is ",this.role);
  }
  
  public UserConfig()
  {
    this.role = this.auth.getAuthFromLocalStorage();
    return this.role.roleName.toUpperCase();
  }

  public PermissionsV2()
  {
    this.role = this.auth.getAuthFromLocalStorage();

    if(this.role.roleName == "Client")
    {
      this._restrictedPermission$.next(false);
      // return false;
    } 
    else if(this.role.roleName == "admin")//this.role.roleName
    {
      this._restrictedPermission$.next(true);
      // return true;
    }
  }

  public userParam() 
  {  
    this.role = this.auth.getAuthFromLocalStorage();

    var selectParam;
    if(this.role.roleName == "Client")
    {      
      selectParam = 5;
      
    }
    else if(this.role.roleName == "admin")
    {      
      selectParam = 2;
      
    }
    return selectParam;
    
  }  

  public userCode()
  {
    this.role = this.auth.getAuthFromLocalStorage();
    var userIdentifier:number = 0;

    if(this.role.roleName == "admin")
    {      
      userIdentifier = 1;
      
    }
    else if(this.role.roleName == "Client")
    {      
      userIdentifier = 3;      
    }

    return userIdentifier;
  }

  public userRoutingPrefix()
  {
    var userURLPrefix:string;
    var href = "";  
    href = this.router.url;
    var url = href.split('?')[0].split('/');
    userURLPrefix = url[1];
    return userURLPrefix;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

}
