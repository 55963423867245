<div class="modal-content"  style="font-family: Verdana;"> 
    <div class="modal-header">
        <div class="modal-title h4" id="example-modal-sizes-title-lg">
          <ng-container *ngIf="pincode">
            <ng-container *ngIf="id">Edit</ng-container>
            <ng-container *ngIf="!id">Create</ng-container>
            Pincode
            <ng-container *ngIf="id">'{{pincode.PinCode}}'</ng-container> 
          </ng-container>
        </div>
    </div>
    <div class="overlay overlay-block cursor-default modal-body" style="min-height: 404px;"> 
        <ng-container *ngIf="isLoading$ | async">
          <div class="overlay-layer bg-transparent">
            <div class="spinner spinner-lg spinner-success"></div>
          </div>
        </ng-container>

        <ng-container *ngIf="pincode">
            <form action="#" class="form form-label-right" [formGroup]="formGroup">
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label><strong class="required">*</strong>State</label>
                        <select class="form-control form-control-lg" name="stateName" (change)="afterStateSelect($event.target.value)"
                          [class.is-invalid]="isControlInvalid('stateName')" formControlName="stateName">
                          <option value="null">Select</option>
                          <option *ngFor="let state of pinCodeService.states$ | async" value="{{state.StateId}}">
                            {{state.StateName}}
                          </option>
                        </select>
                        <!-- <div class="feedback">Please select <b>State</b></div> -->
                    </div>
                    <div class="col-lg-6">
                        <label><strong class="required">*</strong>District</label>
                        <select class="form-control form-control-lg" name="districName"
                          [class.is-invalid]="isControlInvalid('districName')" formControlName="districName">
                          <option value="">Select</option>
                          <option *ngFor="let dist of pinCodeService.district$ | async" value="{{dist.DistrictName}}">
                            {{dist.DistrictName}}
                          </option>
                        </select>
                        <!-- <div class="feedback">Please select <b>District</b></div> -->
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>City</label>
                        <!-- <select class="form-control form-control-m" name="cityName"
                        [class.is-invalid]="isControlInvalid('cityName')" formControlName="cityName">
                            <option value="">Select</option>
                            <option value="{{city.cityName}}" *ngFor="let city of pinCodeService.city$ | async">
                                {{city.cityName}}
                            </option>
                        </select>   -->
                        <input
                        type="text"                        
                        class="form-control form-control-m"
                        autocomplete="off"
                        placeholder="City Name"
                        name="cityName"
                        [class.is-invalid]="isControlInvalid('cityName')"
                        [class.is-valid]="isControlValid('cityName')"
                        formControlName="cityName"
                        [matAutocomplete]="auto"
                      />
                      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <!-- <mat-option *ngFor="let option of filteredOptions | async" [value]="option"> 
                          {{option}}
                        </mat-option> -->
                        <mat-option value="{{city.CityName}}" *ngFor="let city of pinCodeService.city$ | async">
                          {{city.CityName}}
                        </mat-option>
                      </mat-autocomplete>
                      <div class="invalid-feedback" *ngIf="controlHasError('pattern', 'cityName')">
                        City name should only contain alphabets
                      </div> 
                        <!-- <div class="feedback">Please insert <b>City</b></div>   -->
                    </div>  
                    <div class="col-lg-6">
                        <label><strong class="required">*</strong>Locality</label>                            
                        <!-- <select class="form-control form-control-m form-control-white" name="localityName"
                        [class.is-invalid]="isControlInvalid('localityName')" formControlName="localityName">
                            <option value="">Select</option>
                            <option value="{{locality.localityName}}" *ngFor="let locality of pinCodeService.locality$ | async">
                                {{locality.localityName}}                                
                            </option>
                        </select>  -->
                        <input
                          type="text"                        
                          class="form-control form-control-m"
                          autocomplete="off"
                          placeholder="Locality Name"
                          name="localityName"
                          [class.is-invalid]="isControlInvalid('localityName')"
                          [class.is-valid]="isControlValid('localityName')"
                          formControlName="localityName"
                        />
                        <!-- <div class="feedback">Please insert <b>Locality</b></div>    -->
                        <div class="invalid-feedback" *ngIf="controlHasError('pattern', 'localityName')">
                          Locality name should only contain alphabets
                        </div> 
                    </div> 
                </div>  
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label><strong class="required">*</strong>Pickup Branch</label>
                        <select class="form-control form-control-m" name="pickupBranch"
                        [class.is-invalid]="isControlInvalid('pickupBranch')" formControlName="pickupBranch"> 
                            <option value="null">Select</option>
                            <option value="{{branch.BranchId}}" *ngFor="let branch of pinCodeService.branch$ | async">                                
                                {{branch.BranchShortName}}                                
                            </option>
                        </select>    
                        <!-- <div class="feedback">Please select <b>Pickup Branch</b></div>  -->
                    </div>  
                    <div class="col-lg-6">
                        <label><strong class="required">*</strong>Destination</label>
                        <select class="form-control form-control-m" name="destination"
                        [class.is-invalid]="isControlInvalid('destination')" formControlName="destination">
                            <option value="null">Select</option>
                            <option value="{{branch.BranchId}}" *ngFor="let branch of pinCodeService.branch$ | async">
                                {{branch.BranchShortName}} 
                            </option>
                        </select>  
                        <!-- <div class="feedback">Please select <b>Destination</b></div>                        -->
                    </div> 
                </div> 
                <div class="form-group row"> 
                    <div class="col-lg-6">
                        <label class="form-label"><strong class="required">*</strong>Pin Code </label>
                        <!-- [readonly]="disable" -->
                        <input type="number" class="form-control form-control-lg" name="pinCode" 
                        placeholder="Enter State Pin Number" autocomplete="off" [class.is-invalid]="isControlInvalid('pinCode')"
                        [class.is-valid]="isControlValid('pinCode')" formControlName="pinCode" />
                        <div class="valid-feedback" *ngIf="isControlValid('pinCode')">State Pin Number was entered correct</div> 
                        <div class="invalid-feedback" *ngIf="controlHasError('required', 'pinCode')">State Pin Number is required</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'pinCode')">State Pin Number should have at
                          least 6 digits</div>
                          <div class="invalid-feedback" *ngIf="controlHasError('min', 'pinCode')">
                          Model year can't be less than 6 digits
                        </div>
                        <div
                        class="invalid-feedback" *ngIf="controlHasError('max', 'pinCode')">
                        Model year can't be more than 6 digits
                      </div>
                        <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'pinCode')">State Pin Number should have
                          maximum 6 digits</div>
                    </div>      
                </div>  
            </form>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-elevate mr-2" (click)="modal.dismiss()">Cancel</button>    
          <button type="submit" class="btn btn-primary btn-elevate" (click)="save()"
          >Save</button>
    </div>
</div>    