<div class="card card-custom gutter-b">
    <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Country list</h3>
        </div>
        <div class="card-toolbar">
          <button type="button" class="btn btn-primary" (click)="create()">New Country</button> 
        </div>
    </div>
    <div class="card-body">
        <!-- begin: Filtration form -->
        <div class="form form-label-right">
            <div class="form-group row">
              <div class="col-lg-2" [formGroup]="searchGroup">
                <input type="text" class="form-control" name="searchText" placeholder="Search" value=""
                  formControlName="searchTerm" /><small class="form-text text-muted"><b>Search</b> in all fields</small>
              </div>
              <div class="col-lg-4">
                <div class="row" [formGroup]="filterGroup">
                  <div class="col-lg-6">
                    <select class="form-control" name="status" placeholder="Filter by Status" formControlName="status">
                      <option value="">Approved</option>
                      <option value="1">All</option>
                      <option value="2">Deleted</option>
                    </select><small class="form-text text-muted"><b>Filter</b> by Status</small>
                  </div>
                  <!-- <div class="col-lg-6">
                    <select class="form-control" placeholder="Filter by Type" name="type" formControlName="type">
                      <option value="">All</option>
                      <option value="1">Business</option>
                      <option value="2">Individual</option>
                    </select><small class="form-text text-muted"><b>Filter</b> by Type</small>
                  </div> -->
                </div>
              </div>              
            </div>
        </div>
        <!-- end: Filtration form -->

        <!-- begin: Grouping -->
        <ng-container *ngIf="grouping.getSelectedRowsCount()">
        <div class="form">
          <div class="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
            <div class="col-xl-12">
              <div class="form-group form-group-inline">
                <div class="form-label form-label-no-wrap">
                  <label class="font-bold font-danger"><span>Selected records count:
                      <b>{{ grouping.getSelectedRowsCount() }}</b></span></label>
                </div>
                <div>
                  <button type="button" class="btn btn-danger font-weight-bolder font-size-sm mr-1"
                    (click)="deleteSelected()">
                    <i class="fa fa-trash"></i> Delete All
                  </button>&nbsp;
                  <button type="button" class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
                    (click)="fetchSelected()">
                    <i class="fa fa-stream"></i> Fetch Selected
                  </button>&nbsp;
                  <button type="button" class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
                    (click)="updateStatusForSelected()">
                    <i class="fa fa-sync-alt"></i> Update Status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </ng-container>
        <!-- end: Grouping -->

        <!-- begin:Table -->
        <div class="table-responsive angular-bootstrap-table">
            <table class="table table-head-custom table-vertical-center overflow-hidden">
                <thead>
                    <th>
                      COUNTRY NAME
                      <app-sort-icon column="country_Name" [activeColumn]="sorting.column" [activeDirection]="sorting.direction"
                        (sort)="sort($event)"></app-sort-icon>
                    </th>
                    <th>
                      COUNTRY CODE                      <app-sort-icon column="firstName" [activeColumn]="sorting.column" [activeDirection]="sorting.direction"
                        (sort)="sort($event)"></app-sort-icon>
                    </th>
                    <th>
                      CURRENCY
                      <app-sort-icon column="lastName" [activeColumn]="sorting.column" [activeDirection]="sorting.direction"
                        (sort)="sort($event)"></app-sort-icon>
                    </th>
                    <th>
                      ISD CODE
                      <app-sort-icon column="email" [activeColumn]="sorting.column" [activeDirection]="sorting.direction"
                        (sort)="sort($event)"></app-sort-icon>
                    </th>
                    <th>
                      PIN CODE LENGTH
                      <app-sort-icon column="gender" [activeColumn]="sorting.column" [activeDirection]="sorting.direction"
                        (sort)="sort($event)"></app-sort-icon>
                    </th>
                    <th>
                      STATUS
                      <app-sort-icon column="status" [activeColumn]="sorting.column" [activeDirection]="sorting.direction"
                        (sort)="sort($event)"></app-sort-icon>
                    </th>
                    <th>ACTIONS</th>
                </thead>
                <tbody>
                  <tr *ngFor="let country of countryService.items$ | async"> 
                    <td>{{ country.country_Name }}</td>
                    <td>{{ country.country_Code }}</td>
                    <td>{{ country.currency }}</td>
                    <td>{{ country.isD_Code }}</td>
                    <td>{{ country.pin_code_Length }}</td>
                    <td>
                      <span class="label label-lg label-inline" [class.label-light-danger]="country.status === 1"
                        [class.label-light-success]="'true'" [class.label-light-primary]="country.isActive === 3">
                        <ng-container>Approved</ng-container>                 
                      </span>
                    </td>
                    <td>
        
                      <a title="Edit country" class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        (click)="edit(country.countryId)">
                        <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" cacheSVG="true"
                          class="svg-icon svg-icon-md svg-icon-primary">
                        </span>
                      </a>
        
                      <a title="Delete country" class="btn btn-icon btn-light btn-hover-danger btn-sm"
                        (click)="delete(country.countryId)">
                        <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" cacheSVG="true"
                          class="svg-icon svg-icon-md svg-icon-danger">
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>    