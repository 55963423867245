import { AuthModel } from './auth.model';
import { AddressModel } from './address.model';
import { SocialNetworksModel } from './social-networks.model';

export class UserModel extends AuthModel {

  token: string;
  userData: {
    BranchId: number;
    DeliveryEnable: boolean;
    Email: string;
    PickupEnable: boolean;
    RoleName: string;
    UserName: string;
  }

  id: number;
  // token:string;
  UserName: string;
  Password: string;
  FullName: string;
  Email: string;
  Role: string;
  branch: string;
  BranchId: string;

  //#region UNUSED VARIABLES
  pic: string;
  roles: number[];
  occupation: string;
  companyName: string;
  phone: string;
  address?: AddressModel;
  socialNetworks?: SocialNetworksModel;
  // personal information
  firstname: string;
  lastname: string;
  website: string;
  // account information
  language: string;
  timeZone: string;
  communication: {
    email: boolean,
    sms: boolean,
    phone: boolean
  };
  // email settings
  emailSettings: {
    emailNotification: boolean,
    sendCopyToPersonalEmail: boolean,
    activityRelatesEmail: {
      youHaveNewNotifications: boolean,
      youAreSentADirectMessage: boolean,
      someoneAddsYouAsAsAConnection: boolean,
      uponNewOrder: boolean,
      newMembershipApproval: boolean,
      memberRegistration: boolean
    },
    updatesFromKeenthemes: {
      newsAboutKeenthemesProductsAndFeatureUpdates: boolean,
      tipsOnGettingMoreOutOfKeen: boolean,
      thingsYouMissedSindeYouLastLoggedIntoKeen: boolean,
      newsAboutMetronicOnPartnerProductsAndOtherServices: boolean,
      tipsOnMetronicBusinessProducts: boolean
    },  
    //#endregion  
  };

  setUser(user: any) {
    // this.id = user.id;
    this.FullName = user.fullname || '';
    this.UserName = user.username || '';
    this.Role = user.role || '';
    this.BranchId = user.branchname || '';  
    this.Email = user.email || '';
    this.Password = user.password || '';         
    
    // this.pic = user.pic || './assets/media/users/default.jpg';    
    // this.occupation = user.occupation || '';
    // this.companyName = user.companyName || '';
    // this.phone = user.phone || '';
    // this.address = user.address;
    // this.socialNetworks = user.socialNetworks;

    // this.username = user.username || '';
    // this.email = user.email || '';
    // this.pic = user.pic || './assets/media/users/default.jpg';
    // this.roles = user.roles || [];
    // this.token = user.


  }
}
