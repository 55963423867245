import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { PincodeService } from '../_services/pincode.service';
import { debounceTime, distinctUntilChanged, startWith, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditPinCodeModalComponent } from './components/edit-pin-code-modal/edit-pin-code-modal.component';
import { PaginatorState, SortState } from 'src/app/_metronic/shared/crud-table';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ImportPinCodeModalComponent } from './components/import-pin-code-modal/import-pin-code-modal.component';
import { DeletePinCodeModalComponent } from './components/delete-pin-code-modal/delete-pin-code-modal.component';
import { ConvertDownloadCSVService } from 'src/app/_services/convert-download-csv.service';
import { EditPinCodeV2ModalComponent } from './components/edit-pin-code-v2-modal/edit-pin-code-v2-modal.component';
import { AuthService } from '../../auth';
import { UserWiseAccessService } from 'src/app/_services/user-wise-access.service';
import { BookingService } from 'src/app/_services/booking.service';
import { DashboardService } from 'src/app/_services/dashboard.service';

@Component({
  selector: 'app-pin-code',
  templateUrl: './pin-code.component.html',
  styleUrls: ['./pin-code.component.scss']
})
export class PinCodeComponent implements OnInit {
 paginator: PaginatorState;
  sorting: SortState;
  // grouping: GroupingState;
  isLoading: boolean;
  filterGroup: FormGroup;
  searchGroup: FormGroup;
  private subscriptions: Subscription[] = []; 
  public items2$:[]; 
  public restrictPermission:boolean = false; 
  public restrictPermissionV2:boolean = false; 


  constructor
  (
    private fb: FormBuilder,
    private modalService: NgbModal,
    public pinCodeService: PincodeService,
    public convertToCSV: ConvertDownloadCSVService, 
    private auth: AuthService,
    private access: UserWiseAccessService
  ) { }

  ngOnInit(): void 
  {    
    this.filterForm();
    this.searchForm();
    this.pinCodeService.fetch();
    this.paginator = this.pinCodeService.paginator;
    this.sorting = this.pinCodeService.sorting;
    const sb = this.pinCodeService.isLoading$.subscribe(res => this.isLoading = res); 
    // this.restrictPermission = this.access.Permissions();
    this.access.PermissionsV2();
    this.access.restrictedPermission$.subscribe((d:any) => {
      this.restrictPermission = d;
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  // filtration
  filterForm() 
  {
     this.filterGroup = this.fb.group({
       status: [''],
       type: [''],
       searchTerm: [''],
     });
     this.subscriptions.push(
       this.filterGroup.controls.status.valueChanges.subscribe(() => 
         this.filter()
       )
     );
     this.subscriptions.push(
       this.filterGroup.controls.type.valueChanges.subscribe(() => this.filter())
     );
  }

    filter() 
    {
      // const filter = {};
      // const status = this.filterGroup.get('status').value;
      // if (status) {
      //   filter['status'] = status;
      // }
  
      // const type = this.filterGroup.get('type').value;
      // if (type) {
      //   filter['type'] = type;
      // }
      // this.zoneService.patchState({ filter });
    }

    Import()
    { 
      const modalRef = this.modalService.open(ImportPinCodeModalComponent, { size: 's' }); 
      // modalRef.componentInstance.id = id;
      modalRef.result.then(() =>
        this.pinCodeService.fetch(),
        () => { }
      );
    }

      // search
  searchForm() {
    this.searchGroup = this.fb.group({
      searchTerm: [''],
    });
    const searchEvent = this.searchGroup.controls.searchTerm.valueChanges
      .pipe(
        /*
      The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator,
      we are limiting the amount of server requests emitted to a maximum of one every 150ms
      */
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe((val) => this.search(val));
    this.subscriptions.push(searchEvent);
  }

  search(searchTerm: string) {
    searchTerm = searchTerm.toLocaleLowerCase();
    this.pinCodeService.patchState({ searchTerm });
  }

  // sorting
  sort(column: string) {
    const sorting = this.sorting;
    const isActiveColumn = sorting.column === column;
    if (!isActiveColumn) {
      sorting.column = column;
      sorting.direction = 'asc';
    } else {
      sorting.direction = sorting.direction === 'asc' ? 'desc' : 'asc';
    }
    this.pinCodeService.patchState({ sorting });
  }

  // pagination
  paginate(paginator: PaginatorState) {
    this.pinCodeService.patchState({ paginator });
  }

  // form actions
  create() {
      this.edit(undefined);
  }
  
  edit(id: number) 
  {
     //console.log("id is "+ id); 
    // const modalRef = this.modalService.open(EditPinCodeModalComponent, { size: 'm' }); 
    // modalRef.componentInstance.id = id;
    // modalRef.result.then(() =>
    //   this.pinCodeService.fetch(),
    //   () => { }
    // );

    const modalRef = this.modalService.open(EditPinCodeV2ModalComponent, { size: 'm' }); 
    modalRef.componentInstance.id = id;
    modalRef.result.then(() =>
      this.pinCodeService.fetch(),
      () => { }
    );

  }

  delete(id: number) {
    const modalRef = this.modalService.open(DeletePinCodeModalComponent);
    modalRef.componentInstance.id = id;
    modalRef.result.then(() => this.pinCodeService.fetch(), () => { });
  }

  public download()
  {
    let pincodes;
    this.pinCodeService.allItems$.subscribe((data) => {    
      pincodes = data;
    })

    //delete unwanted columns from json data  
    delete  pincodes['createdAt'];
    delete  pincodes['deliveryBranchId'];
    delete  pincodes['isActive'];
    delete  pincodes['pickupBranchId'];
    let heading = ['Country','State', 'District','City', 'Locality','Landmarks','DeliveryBranch','PickupBranch','PinCode'];
    this.convertToCSV.downloadFile(pincodes,'Customer_Pincode_Mapping',heading);
  } 
  
}
