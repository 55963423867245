<div class="modal-content"> 
    <div class="modal-header">
      <div class="modal-title h4" id="example-modal-sizes-title-lg">
        <ng-container *ngIf="country"> 
          <!-- <ng-container *ngIf="country.countryId">Edit</ng-container>
          <ng-container *ngIf="!country.countryId">Create</ng-container>
          country
          <ng-container *ngIf="country.countryId">'{{country.country_Name}}'</ng-container> -->
          <ng-container *ngIf="id">Edit</ng-container>
          <ng-container *ngIf="!id">Create</ng-container>
          country
          <ng-container *ngIf="country.countryId">'{{country.country_Name}}'</ng-container>
        </ng-container>
      </div>
    </div> 
    <div class="overlay overlay-block cursor-default modal-body" style="min-height: 404px;">
        <ng-container>
            <form action="#" class="form form-label-right" [formGroup]="formGroup">
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label class="form-label">Enter Country name</label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="countryName"
                        placeholder="Country Name" autocomplete="off" [class.is-invalid]="isControlInvalid('countryName')" 
                        [class.is-valid]="isControlValid('countryName')" formControlName="countryName" />
                        <div class="valid-feedback" *ngIf="isControlValid('countryName')">Country name was entered correct</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('required', 'countryName')">Country name is required</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'countryName')">Country name should have at
                          least 3 symbols</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'countryName')">Country name should have
                          maximum 100 symbols</div>
                    </div>   
                    <div class="col-lg-6">
                        <label class="form-label">Enter Country Code</label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="countryCode"
                          placeholder="Country Code" autocomplete="off" [class.is-invalid]="isControlInvalid('countryCode')"
                          [class.is-valid]="isControlValid('countryCode')" formControlName="countryCode" />
                        <div class="valid-feedback" *ngIf="isControlValid('countryCode')">Country Code was entered correct</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('required', 'countryCode')">Country Code is required</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'countryCode')">Country Code should have at
                          least 3 symbols</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'countryCode')">Country Code should have
                          maximum 100 symbols</div>
                    </div>         
                </div> 
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label class="form-label">Enter Currency </label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="currency"
                        placeholder="Currency Name" autocomplete="off" [class.is-invalid]="isControlInvalid('currency')"
                        [class.is-valid]="isControlValid('currency')" formControlName="currency" />
                        <div class="valid-feedback" *ngIf="isControlValid('currency')">Currency name was entered correct</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('required', 'currency')">Currency name is required</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'currency')">Currency name should have at
                          least 3 symbols</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'currency')">Currency name should have
                          maximum 100 symbols</div>
                    </div> 
                    <div class="col-lg-6">
                        <label class="form-label">Enter ISD Code </label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="isdCode"
                        placeholder="ISD Code" autocomplete="off" [class.is-invalid]="isControlInvalid('isdCode')"
                        [class.is-valid]="isControlValid('isdCode')" formControlName="isdCode" />
                        <div class="valid-feedback" *ngIf="isControlValid('isdCode')">ISD Code was entered correct</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('required', 'isdCode')">ISD Code is required</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'isdCode')">ISD Code should have at
                          least 3 symbols</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'isdCode')">ISD Code should have
                          maximum 100 symbols</div>
                    </div>            
                </div> 
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label class="form-label">Enter Pin Code Length </label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="pinCodeLength"
                        placeholder="Pin Code Length" autocomplete="off" [class.is-invalid]="isControlInvalid('pinCodeLength')"
                        [class.is-valid]="isControlValid('pinCodeLength')" formControlName="pinCodeLength" />
                        <div class="valid-feedback" *ngIf="isControlValid('pinCodeLength')">Pin Code Length was entered correct</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('required', 'pinCodeLength')">Pin Code Length is required</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'pinCodeLength')">Pin Code Length should have at
                          least 3 symbols</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'pinCodeLength')">Pin Code Length should have
                          maximum 100 symbols</div>
                    </div>           
                </div>        
            </form>
        
          
<!-- <form action="#" class="form form-label-right" [formGroup]="formGroup">
  <div class="form-group row">
      <div class="col-lg-6">
          <label class="form-label">Enter Country name</label>
          <input type="text" class="form-control form-control-lg form-control-solid" name="country_Name"
          placeholder="Country Name" autocomplete="off" [class.is-invalid]="isControlInvalid('country_Name')" 
          [class.is-valid]="isControlValid('country_Name')" formControlName="country_Name" />
          <div class="valid-feedback" *ngIf="isControlValid('country_Name')">Country name was entered correct</div>
          <div class="invalid-feedback" *ngIf="controlHasError('required', 'country_Name')">Country name is required</div>
          <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'country_Name')">Country name should have at
            least 3 symbols</div>
          <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'country_Name')">Country name should have
            maximum 100 symbols</div>
      </div>   
      <div class="col-lg-6">
          <label class="form-label">Enter Country Code</label>
          <input type="text" class="form-control form-control-lg form-control-solid" name="country_Code"
            placeholder="Country Code" autocomplete="off" [class.is-invalid]="isControlInvalid('country_Code')"
            [class.is-valid]="isControlValid('country_Code')" formControlName="country_Code" />
          <div class="valid-feedback" *ngIf="isControlValid('country_Code')">Country Code was entered correct</div>
          <div class="invalid-feedback" *ngIf="controlHasError('required', 'country_Code')">Country Code is required</div>
          <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'country_Code')">Country Code should have at
            least 3 symbols</div>
          <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'country_Code')">Country Code should have
            maximum 100 symbols</div>
      </div>         
  </div> 
  <div class="form-group row">
      <div class="col-lg-6">
          <label class="form-label">Enter Currency </label>
          <input type="text" class="form-control form-control-lg form-control-solid" name="currency"
          placeholder="Currency Name" autocomplete="off" [class.is-invalid]="isControlInvalid('currency')"
          [class.is-valid]="isControlValid('currency')" formControlName="currency" />
          <div class="valid-feedback" *ngIf="isControlValid('currency')">Currency name was entered correct</div>
          <div class="invalid-feedback" *ngIf="controlHasError('required', 'currency')">Currency name is required</div>
          <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'currency')">Currency name should have at
            least 3 symbols</div>
          <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'currency')">Currency name should have
            maximum 100 symbols</div>
      </div> 
      <div class="col-lg-6">
          <label class="form-label">Enter ISD Code </label>
          <input type="text" class="form-control form-control-lg form-control-solid" name="isD_Code"
          placeholder="ISD Code" autocomplete="off" [class.is-invalid]="isControlInvalid('isD_Code')"
          [class.is-valid]="isControlValid('isD_Code')" formControlName="isD_Code" />
          <div class="valid-feedback" *ngIf="isControlValid('isD_Code')">ISD Code was entered correct</div>
          <div class="invalid-feedback" *ngIf="controlHasError('required', 'isD_Code')">ISD Code is required</div>
          <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'isD_Code')">ISD Code should have at
            least 3 symbols</div>
          <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'isD_Code')">ISD Code should have
            maximum 100 symbols</div>
      </div>            
  </div> 
  <div class="form-group row">
      <div class="col-lg-6">
          <label class="form-label">Enter Pin Code Length </label>
          <input type="text" class="form-control form-control-lg form-control-solid" name="pin_code_Length"
          placeholder="Pin Code Length" autocomplete="off" [class.is-invalid]="isControlInvalid('pin_code_Length')"
          [class.is-valid]="isControlValid('pin_code_Length')" formControlName="pin_code_Length" />
          <div class="valid-feedback" *ngIf="isControlValid('pin_code_Length')">Pin Code Length was entered correct</div>
          <div class="invalid-feedback" *ngIf="controlHasError('required', 'pin_code_Length')">Pin Code Length is required</div>
          <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'pin_code_Length')">Pin Code Length should have at
            least 3 symbols</div>
          <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'pin_code_Length')">Pin Code Length should have
            maximum 100 symbols</div>
      </div>           
  </div>        
</form> -->

        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-elevate mr-2" (click)="modal.dismiss()">Cancel</button>
        <!-- <ng-container *ngIf="country"> -->
    
          <button type="submit" class="btn btn-primary btn-elevate" (click)="save()"
            [disabled]="formGroup.invalid">Save</button>
        <!-- </ng-container> -->
    </div>
</div>

