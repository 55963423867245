<div class="modal-content"> 
    <div class="modal-header">
      <div class="modal-title h4" id="example-modal-sizes-title-lg">
        <!-- <ng-container *ngIf="country">  -->
          <!-- <ng-container *ngIf="country.countryId">Edit</ng-container>
          <ng-container *ngIf="!country.countryId">Create</ng-container>
          country
          <ng-container *ngIf="country.countryId">'{{country.country_Name}}'</ng-container> -->
          <!-- <ng-container *ngIf="id">Edit</ng-container>
          <ng-container *ngIf="!id">Create</ng-container>
          country
          <ng-container *ngIf="country.countryId">'{{country.country_Name}}'</ng-container>
        </ng-container> -->
        <ng-container>Add Locality</ng-container>
      </div>
    </div> 
    <div class="overlay overlay-block cursor-default modal-body" style="min-height: 404px;">
        <ng-container>
            <form action="#" class="form form-label-right" [formGroup]="formGroup">
                <div class="form-group row">
                    <div class="col-lg-12">
                        <label>Select Pincode</label>
                        <select class="form-control form-control-lg form-control-solid" name="pincode"
                        [class.is-invalid]="isControlInvalid('pincode')" formControlName="pincode">
                            <option value="shimla">171001</option>
                        </select>                       
                    </div>  
                </div> 
                <div class="form-group row">
                    <div class="col-lg-12">
                        <label class="form-label">Locality </label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="locality"
                        placeholder="Enter state code" autocomplete="off" [class.is-invalid]="isControlInvalid('locality')"
                        [class.is-valid]="isControlValid('locality')" formControlName="locality" />
                        <div class="valid-feedback" *ngIf="isControlValid('locality')">State code was entered correct</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('required', 'locality')">State code is required</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'locality')">State code should have at
                          least 3 symbols</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'locality')">State code should have
                          maximum 100 symbols</div>
                    </div>            
                </div>       
            </form>                
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-elevate mr-2" (click)="modal.dismiss()">Cancel</button>    
          <button type="submit" class="btn btn-primary btn-elevate" (click)="save()"
            [disabled]="formGroup.invalid">Save</button>
    </div>
</div>


