import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ZoneService } from '../_services/zone.service';
import { IFilterView, ISearchView, IGroupingView, ISortView, IUpdateStatusForSelectedAction, IFetchSelectedAction, IDeleteSelectedAction, IDeleteAction, IEditAction, ICreateAction, PaginatorState, SortState, GroupingState } from 'src/app/_metronic/shared/crud-table';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditCountryModalComponent } from '../country/components/edit-country-modal/edit-country-modal.component';
import { EditZoneModalComponent } from './components/edit-zone-modal/edit-zone-modal.component';

@Component({
  selector: 'app-zone',
  templateUrl: './zone.component.html',
  styleUrls: ['./zone.component.scss']
})
export class ZoneComponent 
implements 
OnInit,
OnDestroy,
ICreateAction,
IEditAction,
IDeleteAction,
IDeleteSelectedAction,
IFetchSelectedAction,
IUpdateStatusForSelectedAction,
ISortView,
IFilterView,
IGroupingView,
ISearchView,
IFilterView   
{

  paginator: PaginatorState;
  sorting: SortState;
  grouping: GroupingState;
  isLoading: boolean;
  filterGroup: FormGroup;
  searchGroup: FormGroup;
  private subscriptions: Subscription[] = []; 
  public items2$:[];

  constructor
  (
    private fb: FormBuilder,
    private modalService: NgbModal,
    public zoneService: ZoneService,
    private http: HttpClient
  ) { }

  ngOnInit(): void { 
    this.filterForm();
    this.searchForm();
    // this.zoneService.fetch();
    // this.grouping = this.zoneService.grouping;
    // this.paginator = this.zoneService.paginator;
    // this.sorting = this.zoneService.sorting;
    // const sb = this.zoneService.isLoading$.subscribe(res => this.isLoading = res);
    // this.subscriptions.push(sb);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

   // filtration
   filterForm() {
    this.filterGroup = this.fb.group({
      status: [''],
      type: [''],
      searchTerm: [''],
    });
    this.subscriptions.push(
      this.filterGroup.controls.status.valueChanges.subscribe(() => 
        this.filter()
      )
    );
    this.subscriptions.push(
      this.filterGroup.controls.type.valueChanges.subscribe(() => this.filter())
    );
  }
    
  filter() {
    // const filter = {};
    // const status = this.filterGroup.get('status').value;
    // if (status) {
    //   filter['status'] = status;
    // }

    // const type = this.filterGroup.get('type').value;
    // if (type) {
    //   filter['type'] = type;
    // }
    // this.zoneService.patchState({ filter });
  }

  // search
  searchForm() {
    this.searchGroup = this.fb.group({
      searchTerm: [''],
    });
    const searchEvent = this.searchGroup.controls.searchTerm.valueChanges
      .pipe(
        /*
      The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator,
      we are limiting the amount of server requests emitted to a maximum of one every 150ms
      */
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe((val) => this.search(val));
    this.subscriptions.push(searchEvent);
  }

  search(searchTerm: string) {
    // this.zoneService.patchState({ searchTerm });
  }

  // sorting
  sort(column: string) {
    // const sorting = this.sorting;
    // const isActiveColumn = sorting.column === column;
    // if (!isActiveColumn) {
    //   sorting.column = column;
    //   sorting.direction = 'asc';
    // } else {
    //   sorting.direction = sorting.direction === 'asc' ? 'desc' : 'asc';
    // }
    // this.zoneService.patchState({ sorting });
  }

  // pagination
  paginate(paginator: PaginatorState) {
    // this.zoneService.patchState({ paginator });
  }

  // form actions
  create() {
    this.edit(undefined);
  }

  edit(id: number) {
    // console.log("id is "+ id);
    const modalRef = this.modalService.open(EditZoneModalComponent, { size: 'm' }); 
    modalRef.componentInstance.id = id;
    modalRef.result.then(() =>
      this.zoneService.fetch(),
      () => { }
    );
  }

  delete(id: number) {
    // const modalRef = this.modalService.open(DeleteCustomerModalComponent);
    // modalRef.componentInstance.id = id;
    // modalRef.result.then(() => this.zoneService.fetch(), () => { });
  }

  deleteSelected() {
    // const modalRef = this.modalService.open(DeleteCustomersModalComponent);
    // modalRef.componentInstance.ids = this.grouping.getSelectedRows();
    // modalRef.result.then(() => this.zoneService.fetch(), () => { });
  }

  updateStatusForSelected() {
    // const modalRef = this.modalService.open(UpdateCustomersStatusModalComponent);
    // modalRef.componentInstance.ids = this.grouping.getSelectedRows();
    // modalRef.result.then(() => this.zoneService.fetch(), () => { });
  }

  fetchSelected() {
    // const modalRef = this.modalService.open(FetchCustomersModalComponent);
    // modalRef.componentInstance.ids = this.grouping.getSelectedRows();
    // modalRef.result.then(() => this.zoneService.fetch(), () => { });
  } 

}
