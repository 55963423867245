import { Injectable, Inject, OnDestroy } from '@angular/core';
import { TableService } from 'src/app/_metronic/shared/crud-table';
import { Locality } from '../_models/locality.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LocalityService extends TableService<Locality> implements OnDestroy{

  constructor(@Inject(HttpClient) http) 
  {
    super(http);
  } 

  ngOnDestroy() 
  {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
  
}
