<div class="modal-content"> 
    <div class="modal-header">
        <div class="modal-title h4" id="example-modal-sizes-title-lg">
            <ng-container *ngIf="zone"> 
                <!-- <ng-container *ngIf="country.countryId">Edit</ng-container>
                <ng-container *ngIf="!country.countryId">Create</ng-container>
                country
                <ng-container *ngIf="country.countryId">'{{country.country_Name}}'</ng-container> -->
                <!-- <ng-container *ngIf="id">Edit</ng-container>
                <ng-container *ngIf="!id">Create</ng-container>
                zone
                <ng-container *ngIf="zone.ZoneId">'{{zone.Zone_Name}}'</ng-container> -->                
              </ng-container>
              <ng-container>Add Zone</ng-container>
        </div>
    </div>
    <div class="overlay overlay-block cursor-default modal-body" style="min-height: 404px;">
        <ng-container>
            <form action="#" class="form form-label-right" [formGroup]="formGroup">
                <div class="form-group row">
                    <div class="col-lg-12">
                        <label>Select Country</label>
                        <select class="form-control form-control-lg form-control-solid" name="countryName"
                        [class.is-invalid]="isControlInvalid('countryName')" formControlName="countryName">
                            <option value="Female">INDIA</option>
                        </select>                       
                    </div>    
                </div> 
                <div class="form-group row">
                    <div class="col-lg-12">
                        <div class="feedback">Please enter Zone Name</div>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="zoneName"
                        placeholder="Zone Name" autocomplete="off" [class.is-invalid]="isControlInvalid('zoneName')" 
                        [class.is-valid]="isControlValid('zoneName')" formControlName="zoneName" />                      
                    </div>    
                </div> 
            </form>
        </ng-container>
        <div class="modal-footer">
            <button type="button" class="btn btn-light btn-elevate mr-2" (click)="modal.dismiss()">Cancel</button>
            <!-- <ng-container *ngIf="country"> -->
        
              <button type="submit" class="btn btn-primary btn-elevate" (click)="save()"
                [disabled]="formGroup.invalid">Save</button>
            <!-- </ng-container> -->
        </div>
    </div>
</div>
