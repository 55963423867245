import { Component, OnInit, Input } from '@angular/core';
import { City } from '../../../_models/city.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-city-modal',
  templateUrl: './edit-city-modal.component.html',
  styleUrls: ['./edit-city-modal.component.scss']
})
export class EditCityModalComponent implements OnInit {
  @Input() id: number;
  public Id;
  isLoading$;
  city: City;
  formGroup: FormGroup;

  constructor(  public modal: NgbActiveModal ) { }

  ngOnInit(): void 
  {
    this.loadCountry();
  }

  loadCountry() 
  {
    if(!this.id)
    {    
      // console.log("called inside load country");  
      // this.country = EMPTY_COUNTRY;
      this.loadForm();
    }    
    else
    {
      // console.log("called inside load country else");
      // this.country = {
      //   id: 1,
      //   countryId: 1,
      //   country_Name: 'INDIA',
      //   country_Code: '',
      //   currency: '',
      //   isD_Code: '',
      //   pin_code_Length: '',
      //   status: undefined,
      //   isActive: undefined,
      //   created_at: '',
      //   userId: undefined
      // };
      // const sb = this.countryService.getItemById(this.id).pipe( 
      //   first(),
      //   catchError((errorMessage) => {
      //     this.modal.dismiss(errorMessage);
      //     return of(EMPTY_COUNTRY);
      //   })
      // ).subscribe((country: Country) => {
      //   this.country = country;
      //   this.loadForm();
      // });
      // this.subscriptions.push(sb);
      // this.country.countryId = this.id;      
      this.Id = this.id;
      // console.log("id is "+this.id);
      // this.loadForm();
    }
  }

  loadForm()
  {
     this.formGroup = new FormGroup({
      countryName    :   new FormControl('',Validators.required), 
      zoneName       :   new FormControl('',Validators.required),
      stateName      :   new FormControl('',Validators.required),
      cityTier       :   new FormControl('',Validators.required),
      cityName       :   new FormControl('',Validators.required),
      cityCode       :   new FormControl('',Validators.required),
      metro          :   new FormControl('',Validators.required),
      airport        :   new FormControl('',Validators.required)
    });
  }

  save() {}

     // helpers for View
     isControlValid(controlName: string): boolean {
      const control = this.formGroup.controls[controlName];
      return control.valid && (control.dirty || control.touched);
    }
  
    isControlInvalid(controlName: string): boolean {
      const control = this.formGroup.controls[controlName];
      return control.invalid && (control.dirty || control.touched);
    }
  
    controlHasError(validation, controlName): boolean {
      const control = this.formGroup.controls[controlName];
      return control.hasError(validation) && (control.dirty || control.touched);
    }
  
    isControlTouched(controlName): boolean {
      const control = this.formGroup.controls[controlName];
      return control.dirty || control.touched;
    }

}
