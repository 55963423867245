import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UrlService } from 'src/app/_services/url.service';

@Component({
  selector: 'app-customer-tracking',
  templateUrl: './customer-tracking.component.html',
  styleUrls: ['./customer-tracking.component.scss']
})
export class CustomerTrackingComponent implements OnInit {

  data:any;
  events: any;

  constructor(private http: HttpClient, private _url: UrlService, private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let AWB_NUMBER = params['awb'];
      console.log(AWB_NUMBER); // Print the parameter to the console. 
      if(AWB_NUMBER != null)
      {
        this.trackingHistory(AWB_NUMBER);
      }else{
        alert("Please provide a valid AWB number.");
      }
  });
  }

  trackingHistory(awbNo:string)
  {
    // this._isLoading$.next(true);
    // this._errorMessage.next('');
    var _params = "?awbNo=" + awbNo;
    // var _url = this._url.getoldAWBhistory + _params;    
    // var _url = this._url.getv2AWBTrackingv1 + _params; 
    console.log();    
    var _url = "https://v2.holisoldelivery.com/api/Tracking/TrackMyOrder?awbNo=" + awbNo;
    //console.log(_url, awbNo);    
    return this.http.get<any[]>(_url)
    .subscribe((Response:any) => {
      console.log(Response);
      this.data = Response;
      this.events = this.data.TrackResult;
      // this._ItemsHistory$.next(Response);   
      // this._isLoading$.next(false)      
    })
    // finalize(() => this._isLoading$.next(false))

  }
}
