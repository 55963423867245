import { Component, OnInit, OnDestroy } from '@angular/core';
import { PaginatorState, SortState, GroupingState, ICreateAction, IEditAction, IDeleteAction, IDeleteSelectedAction, IFetchSelectedAction, IUpdateStatusForSelectedAction, ISortView, IFilterView, IGroupingView, ISearchView } from 'src/app/_metronic/shared/crud-table';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CountryService } from '../_services/country.service';
import { EditCountryModalComponent } from './components/edit-country-modal/edit-country-modal.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent 
implements
OnInit,
OnDestroy,
ICreateAction,
IEditAction,
IDeleteAction,
IDeleteSelectedAction,
IFetchSelectedAction,
IUpdateStatusForSelectedAction,
ISortView,
IFilterView,
IGroupingView,
ISearchView,
IFilterView  
{

  //#region Country Static Data
  public country: any = [
    {
      "CountryId": 1,
      "Country_Name": "INDIA",
      "Country_Code": "IND",
      "Currency": "INR",
      "ISD_Code": "91",
      "Pin_code_Length": 6,
      "status": "Approved",
      "isActive": 1,
      "Created_at": "29-11-2018 12:47:00 PM",
      "link": "<a class=\"btn btn-link primary shiny\" style=\"font-weight:bold; text-decoration: none !important;\" href=\"javascript:;\" onClick=\"viewcountry('','country.html', '','1');\"><i class=\"fa fa-eye\" style=\"font-size:20px\"></i></a>"
    }
  ];
  
  paginator: PaginatorState;
  sorting: SortState;
  grouping: GroupingState;
  isLoading: boolean;
  filterGroup: FormGroup;
  searchGroup: FormGroup;
  private subscriptions: Subscription[] = []; 
  public items2$:[];
  
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    public countryService: CountryService,
    private http: HttpClient
  ) { } 

    // angular lifecircle hooks
    ngOnInit(): void {
      this.filterForm();
      this.searchForm();
      this.countryService.fetch();
      this.grouping = this.countryService.grouping;
      this.paginator = this.countryService.paginator;
      this.sorting = this.countryService.sorting;
      const sb = this.countryService.isLoading$.subscribe(res => this.isLoading = res);
      this.subscriptions.push(sb);
    }

    ngOnDestroy() {
      this.subscriptions.forEach((sb) => sb.unsubscribe());
    }

      // filtration
  filterForm() {
    this.filterGroup = this.fb.group({
      status: [''],
      type: [''],
      searchTerm: [''],
    });
    this.subscriptions.push(
      this.filterGroup.controls.status.valueChanges.subscribe(() =>
        this.filter()
      )
    );
    this.subscriptions.push(
      this.filterGroup.controls.type.valueChanges.subscribe(() => this.filter())
    );
  }

  filter() {
    const filter = {};
    const status = this.filterGroup.get('status').value;
    if (status) {
      filter['status'] = status;
    }

    const type = this.filterGroup.get('type').value;
    if (type) {
      filter['type'] = type;
    }
    this.countryService.patchState({ filter });
  }

  // search
  searchForm() {
    this.searchGroup = this.fb.group({
      searchTerm: [''],
    });
    const searchEvent = this.searchGroup.controls.searchTerm.valueChanges
      .pipe(
        /*
      The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator,
      we are limiting the amount of server requests emitted to a maximum of one every 150ms
      */
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe((val) => this.search(val));
    this.subscriptions.push(searchEvent);
  }
  
  search(searchTerm: string) {
    this.countryService.patchState({ searchTerm });
  }

    // sorting
  sort(column: string) {
    const sorting = this.sorting;
    const isActiveColumn = sorting.column === column;
    if (!isActiveColumn) {
      sorting.column = column;
      sorting.direction = 'asc';
    } else {
      sorting.direction = sorting.direction === 'asc' ? 'desc' : 'asc';
    }
    this.countryService.patchState({ sorting });
  }

  // pagination
  paginate(paginator: PaginatorState) {
    this.countryService.patchState({ paginator });
  }

  // form actions
  create() {
    this.edit(undefined);
  }

  edit(id: number) {
    // console.log("id is "+ id);
    const modalRef = this.modalService.open(EditCountryModalComponent, { size: 'xl' }); 
    modalRef.componentInstance.id = id;
    modalRef.result.then(() =>
      this.countryService.fetch(),
      () => { }
    );
  }

  delete(id: number) {
    // const modalRef = this.modalService.open(DeleteCustomerModalComponent);
    // modalRef.componentInstance.id = id;
    // modalRef.result.then(() => this.countryService.fetch(), () => { });
  }

  deleteSelected() {
    // const modalRef = this.modalService.open(DeleteCustomersModalComponent);
    // modalRef.componentInstance.ids = this.grouping.getSelectedRows();
    // modalRef.result.then(() => this.countryService.fetch(), () => { });
  }

  updateStatusForSelected() {
    // const modalRef = this.modalService.open(UpdateCustomersStatusModalComponent);
    // modalRef.componentInstance.ids = this.grouping.getSelectedRows();
    // modalRef.result.then(() => this.countryService.fetch(), () => { });
  }

  fetchSelected() {
    // const modalRef = this.modalService.open(FetchCustomersModalComponent);
    // modalRef.componentInstance.ids = this.grouping.getSelectedRows();
    // modalRef.result.then(() => this.countryService.fetch(), () => { });
  } 

}
