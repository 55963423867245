import { Injectable, OnDestroy, Inject } from '@angular/core';
import { ITableState, PaginatorState, SortState, GroupingState, TableService } from 'src/app/_metronic/shared/crud-table';
import { Zone } from '../_models/zone.model';
import { HttpClient } from '@angular/common/http';

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined
};


@Injectable({
  providedIn: 'root'
})
export class ZoneService extends TableService<Zone> implements OnDestroy {

  constructor(@Inject(HttpClient) http) {
    super(http);
  } 

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
}
