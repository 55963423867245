import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { timeout, catchError } from 'rxjs/operators';

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor { 

  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`; 

  constructor(private injector: Injector) //private auth:AuthService,private _notification: NotificationService, private router: Router
   {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {    
    let url;
    url = request.url;
    url = url.split('?')[0].split('/').pop();
    if(url != "login" && url != "register" && url != "TrackMyOrder")
    {
      var jwtToken = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      jwtToken = jwtToken.accessToken;
  
      let tokenizedReq = request.clone({
        setHeaders: {
          Authorization: `Bearer ${jwtToken}`
        }
      })
      return next.handle(tokenizedReq)
      .pipe(
        catchError((response) => {
          
          if (response.status === 400) {
            if(response.error != null && response.error.data != undefined && response.error.data != null && response.error.data.length > 0) {
                var html: string = "";
                response.error.data.forEach((data: string) => {
                html += data + "</br>";
              });
              
              // this._notification.warning(html)
            }
          }
          else if (response.status === 401) {
            // this._notification.error("Unauthorized");
            // this.logout();
            //this.auth.logout(); 
            localStorage.removeItem(this.authLocalStorageToken);
            // this.router.navigate(['/auth/login'], {
            //   queryParams: {},
            // });         
          }
          else if (response.status === 404) {
            // this._notification.info("Service is Temporarily unavailable. Please contact administrator.");
          }
          return throwError(response);
        }),
        timeout(15000),
        // finalize(() => $('.loading').hide())
      );
    }
    return next.handle(request)
    .pipe(
      catchError((response) => {
        
        if (response.status === 400) {
          if(response.error != null && response.error.data != undefined && response.error.data != null && response.error.data.length > 0) {
              var html: string = "";
              response.error.data.forEach((data: string) => {
              html += data + "</br>";
            });
            
            // this._notification.warning(html)
          }
        }
        else if (response.status === 401) {
          // this._notification.error("Unauthorized");
          // this.auth.logout();          
        }
        else if (response.status === 404) {
          // this._notification.info("Service is Temporarily unavailable. Please contact administrator.");
        }
        return throwError(response);
      }),
      timeout(15000),
    ) 
  }    
}
