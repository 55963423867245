import { Component, OnInit, Input } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { PincodeService } from '../../../_services/pincode.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/_services/notification.service';
import { tap, catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-delete-pin-code-modal',
  templateUrl: './delete-pin-code-modal.component.html',
  styleUrls: ['./delete-pin-code-modal.component.scss']
})
export class DeletePinCodeModalComponent implements OnInit {

  @Input() id: number;
  isLoading = false;
  subscriptions: Subscription[] = [];

  constructor
  (
    private pincode: PincodeService , 
    public modal: NgbActiveModal, 
    private notify: NotificationService
  ) { }

  ngOnInit(): void {
  }

  deletePincode() 
  {
    //console.log(this.id);
    this.isLoading = true;
    const sb = this.pincode.delete(this.id).pipe(
      tap(() => this.modal.close()),
      catchError((err) => {
        this.modal.dismiss(err);
        return of(undefined);
      }),
      finalize(() => {
        this.isLoading = false;
      })
     ).subscribe((response) => {
       this.notify.success(response.data);
        // console.log("response to delete", response);
     });
  }
}
