<!-- <div class="modal-content"> 
    <div class="modal-header">
        <div class="modal-title h4" id="example-modal-sizes-title-lg">
            <ng-container>Import Data</ng-container>
        </div>
    </div>
    <div class="overlay overlay-block cursor-default modal-body" style="min-height: 404px;">
        <ng-container>
            <form action="#" class="form form-label-right" [formGroup]="formGroup" >
                <div class="form-group row">
                    <div class="col-lg-12">
                      <button type="button" class="btn btn-primary">Download Sample CSV</button> 
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-12">
                      <input type="file" #file placeholder="Choose file"  style="display:none;" (change)="setFileName(file.files)" formControlName="import" accept=".csv">
                      <button type="button" class="btn btn-primary roundButton" (click)="file.click()"><img style="width: 40px; height: 50px;" src="/assets/media/svg/files/csv.svg"></button>                      
                      <mat-form-field class="fileName-form-field">
                        <mat-label>Upload CSV</mat-label>
                        <input matInput disabled type="text" id="fileName" disabled formControlName="fileName">
                      </mat-form-field>

                    </div>
                    <div class="col-md-12">
                      <span class="upload"   *ngIf="progress > 0">
                        {{progress}}%
                      </span>
                      <span class="upload" *ngIf="message">
                        {{message}}
                      </span>
                    </div>                  
                </div>              
            </form>
        </ng-container>        
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-elevate mr-2" (click)="modal.dismiss()">Cancel</button>    
          <button type="submit" class="btn btn-primary btn-elevate" (click)="uploadFile(file.files)"
            [disabled]="formGroup.invalid">Save</button>
    </div>
</div> -->

<div class="modal-content"> 
  <div class="modal-header">
      <div class="modal-title h4" id="example-modal-sizes-title-lg">
          <ng-container>Export Data</ng-container>
      </div>
  </div>
  <div class="overlay overlay-block cursor-default modal-body" style="min-height: 404px;">
      <ng-container>
          <form action="#" class="form form-label-right" [formGroup]="formGroup" >
              <div class="form-group row">
                  <div class="col-lg-12">
                    <button type="button" class="btn btn-primary" (click)="sampleDownload()">Download Sample CSV</button> 
                  </div>
              </div>
              <div class="form-group row">
                  <div class="col-lg-12">
                    <input type="file" #file placeholder="Choose file"  style="display:none;" (change)="setFileName(file.files)" formControlName="import" accept=".csv">
                    <button type="button" class="btn btn-primary roundButton" (click)="file.click()"><img style="width: 40px; height: 50px;" src="/assets/media/svg/files/csv.svg"></button>                      
                    <mat-form-field class="fileName-form-field">
                      <mat-label>Upload CSV</mat-label>
                      <input matInput disabled type="text" id="fileName" disabled formControlName="fileName">
                    </mat-form-field>

                  </div>
                  <div class="col-md-12">
                    <span class="upload"   *ngIf="progress > 0">
                      {{progress}}%
                    </span>
                    <span class="upload" *ngIf="message">
                      {{message}}
                    </span>
                  </div>                  
              </div>              
          </form>
      </ng-container>        
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-light btn-elevate mr-2" (click)="modal.dismiss()">Cancel</button>    
        <button type="submit" class="btn btn-primary btn-elevate" (click)="fileChangeListener(file.files)"
          [disabled]="formGroup.invalid">Save</button> 
          <!-- (click)="uploadFile(file.files)"  -->
  </div>
</div>
<!-- <input type="file" accept=".csv" (change)="fileChangeListener($event)" />   -->