<div class="card card-custom gutter-b" style="font-family: Verdana;">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">Pin Code list</h3> 
        </div>
        <div class="card-toolbar">
          <!-- begin: Filtration form -->
          <div class="form-group row">
              <div class="" [formGroup]="searchGroup">
                  <input type="text" class="form-control" name="searchText" placeholder="Search Pincode" value=""
                    formControlName="searchTerm" />
                    <!-- <small class="form-text text-muted"><b>Search</b> in all fields</small> -->
              </div>

              <button type="button" class="btn btn-primary newButton filter" (click)="create()" *ngIf="restrictPermission">New Pin Code </button> 

              <button mat-icon-button [matMenuTriggerFor]="download_upload" class="buttonOperation filter"> 
                <mat-icon class="matIcon"> import_export</mat-icon>
              </button>
              <mat-menu #download_upload="matMenu"> 
                <button mat-menu-item [matMenuTriggerFor]="downloadable">
                  <mat-icon>save_alt</mat-icon>
                  Download
                </button>
                <button mat-menu-item [matMenuTriggerFor]="upload" *ngIf="restrictPermission">
                  <mat-icon> publish</mat-icon>
                  Upload
                </button>
              </mat-menu>

              <mat-menu #downloadable="matMenu">            
                <button type="button" class="btn Button roundButton" (click)="download()"> 
                  <mat-icon>save_alt</mat-icon>             
                  Sample CSV
                </button>
              </mat-menu>
    
              <mat-menu #upload="matMenu">
                <button type="button" class="btn roundButton Button" (click)="Import">  
                  <mat-icon> publish</mat-icon>            
                  Pincode List
                </button>              
              </mat-menu>

          </div>
      <!-- end: Filtration form -->
        </div>
    </div>
    <div class="card-body"> 
         <!-- begin:Table -->
         <div class="table-responsive angular-bootstrap-table">
          <div class="scrollit">
            <table class="table table-head-custom table-vertical-center">
                <thead>
                  <th>
                      Pin Code
                      <app-sort-icon column="pinCode" [activeColumn]="sorting.column" [activeDirection]="sorting.direction"
                      (sort)="sort($event)"></app-sort-icon>
                  <!-- </th>
                  <th>
                      District
                  </th> -->
                  <th>
                      State
                      <app-sort-icon column="state" [activeColumn]="sorting.column" [activeDirection]="sorting.direction"
                      (sort)="sort($event)"></app-sort-icon>
                  </th>
                  <th>
                      City
                      <app-sort-icon column="city" [activeColumn]="sorting.column" [activeDirection]="sorting.direction"
                      (sort)="sort($event)"></app-sort-icon>
                  </th>                  
                  <th>
                      Locality
                      <app-sort-icon column="locality" [activeColumn]="sorting.column" [activeDirection]="sorting.direction"
                      (sort)="sort($event)"></app-sort-icon>
                  </th>
                  <th *ngIf="restrictPermission">
                      Action
                  </th>                 
                </thead>
                <tbody>
                    <tr *ngFor="let pincode of pinCodeService.items$ | async" class="image">
                        <td>{{ pincode.PinCode }}</td> 
                        <td>{{ pincode.State }}</td>
                        <td>{{ pincode.City }}</td>
                        <td>{{ pincode.Locality }}</td>
                        <td *ngIf="restrictPermission">            
                          <a title="Edit pincode" class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                            <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" cacheSVG="true"
                            (click)="edit(pincode.PinCode)"  class="svg-icon svg-icon-md svg-icon-primary">
                            </span>
                          </a>
            
                          <a title="Delete pincode" class="btn btn-icon btn-light btn-hover-danger btn-sm">
                            <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" cacheSVG="true"
                            (click)="delete(pincode.PinCode)" class="svg-icon svg-icon-md svg-icon-danger"> 
                            </span> 
                          </a>
                        </td>
                    </tr>
                </tbody>
            </table>
          </div>
         </div>
         <app-paginator class="d-flex justify-content-between align-items-center flex-wrap" [paginator]="paginator"
      [isLoading]="isLoading" (paginate)="paginate($event)"></app-paginator>  
    </div>
</div>




