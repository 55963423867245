import { Component, OnInit, OnDestroy } from '@angular/core';
import { ZoneService } from '../_services/zone.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StateService } from '../_services/state.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditStateModalComponent } from './components/edit-state-modal/edit-state-modal.component';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss']
})
export class StateComponent 
implements
 OnInit,
 OnInit,
 OnDestroy
 {
  // paginator: PaginatorState;
  // sorting: SortState;
  // grouping: GroupingState;
  // isLoading: boolean;
  filterGroup: FormGroup;
  searchGroup: FormGroup;
  private subscriptions: Subscription[] = []; 
  public items2$:[];

  constructor
  (
    private fb: FormBuilder,
    private modalService: NgbModal,
    public stateService: StateService
  ) { }

  ngOnInit(): void 
  {
    this.filterForm();
    this.searchForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  // filtration
  filterForm() 
  {
     this.filterGroup = this.fb.group({
       status: [''],
       type: [''],
       searchTerm: [''],
     });
     this.subscriptions.push(
       this.filterGroup.controls.status.valueChanges.subscribe(() => 
         this.filter()
       )
     );
     this.subscriptions.push(
       this.filterGroup.controls.type.valueChanges.subscribe(() => this.filter())
     );
  }

    filter() 
    {
      // const filter = {};
      // const status = this.filterGroup.get('status').value;
      // if (status) {
      //   filter['status'] = status;
      // }
  
      // const type = this.filterGroup.get('type').value;
      // if (type) {
      //   filter['type'] = type;
      // }
      // this.zoneService.patchState({ filter });
    }

      // search
  searchForm() {
    this.searchGroup = this.fb.group({
      searchTerm: [''],
    });
    const searchEvent = this.searchGroup.controls.searchTerm.valueChanges
      .pipe(
        /*
      The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator,
      we are limiting the amount of server requests emitted to a maximum of one every 150ms
      */
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe((val) => this.search(val));
    this.subscriptions.push(searchEvent);
  }

  search(searchTerm: string) {
    // this.zoneService.patchState({ searchTerm });
  }

    // form actions
    create() {
      this.edit(undefined);
    }
  
    edit(id: number) {
      // console.log("id is "+ id);
      const modalRef = this.modalService.open(EditStateModalComponent, { size: 'xl' }); 
      modalRef.componentInstance.id = id;
      modalRef.result.then(() =>
        this.stateService.fetch(),
        () => { }
      );
    }

}
