import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LocalityService } from '../_services/locality.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditLocalityModalComponent } from './components/edit-locality-modal/edit-locality-modal.component';

@Component({
  selector: 'app-locality',
  templateUrl: './locality.component.html',
  styleUrls: ['./locality.component.scss']
})
export class LocalityComponent implements OnInit {
 // paginator: PaginatorState;
  // sorting: SortState;
  // grouping: GroupingState;
  // isLoading: boolean;
  filterGroup: FormGroup;
  searchGroup: FormGroup;
  private subscriptions: Subscription[] = []; 
  public items2$:[];

  constructor
  (
    private fb: FormBuilder,
    private modalService: NgbModal,
    public localityService: LocalityService 
  ) { }

  ngOnInit(): void 
  {
    this.filterForm();
    this.searchForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  // filtration
  filterForm() 
  {
     this.filterGroup = this.fb.group({
       status: [''],
       type: [''],
       searchTerm: [''],
     });
     this.subscriptions.push(
       this.filterGroup.controls.status.valueChanges.subscribe(() => 
         this.filter()
       )
     );
     this.subscriptions.push(
       this.filterGroup.controls.type.valueChanges.subscribe(() => this.filter())
     );
  }

    filter() 
    {
      // const filter = {};
      // const status = this.filterGroup.get('status').value;
      // if (status) {
      //   filter['status'] = status;
      // }
  
      // const type = this.filterGroup.get('type').value;
      // if (type) {
      //   filter['type'] = type;
      // }
      // this.zoneService.patchState({ filter });
    }

      // search
  searchForm() {
    this.searchGroup = this.fb.group({
      searchTerm: [''],
    });
    const searchEvent = this.searchGroup.controls.searchTerm.valueChanges
      .pipe(
        /*
      The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator,
      we are limiting the amount of server requests emitted to a maximum of one every 150ms
      */
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe((val) => this.search(val));
    this.subscriptions.push(searchEvent);
  }

  search(searchTerm: string) {
    // this.zoneService.patchState({ searchTerm });
  }

  // form actions
  create() {
        this.edit(undefined);
  }

  edit(id: number) {
        // console.log("id is "+ id);
        const modalRef = this.modalService.open(EditLocalityModalComponent, { size: 'm' }); 
        modalRef.componentInstance.id = id;
        modalRef.result.then(() =>
          this.localityService.fetch(),
          () => { }
        );
  }

}
