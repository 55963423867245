import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { strict } from 'assert';
import { PincodeBulkUpload } from '../../../_models/pincodeBulkUpload.model';
import { PincodeService } from '../../../_services/pincode.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { NotificationService } from 'src/app/_services/notification.service';
import { ConvertDownloadCSVService } from 'src/app/_services/convert-download-csv.service';


@Component({
  selector: 'app-import-pin-code-modal',
  templateUrl: './import-pin-code-modal.component.html',
  styleUrls: ['./import-pin-code-modal.component.scss']
})
export class ImportPinCodeModalComponent implements OnInit {
  formGroup: FormGroup;
  public response: {dbPath: ''};
  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();
  public user;
  public users: []; 
  isLoading = false;
  private subscriptions: Subscription[] = [];

  csvRecords: any[] = [];
  header = true;
  @ViewChild('fileImportInput', { static: false }) fileImportInput: any;


  constructor
  (
    private http: HttpClient, 
    public modal: NgbActiveModal, 
    private ngxCsvParser: NgxCsvParser,
    public pinCodeService: PincodeService,
    private notify: NotificationService,
    public convertToCSV: ConvertDownloadCSVService
  ) { }

  ngOnInit(): void 
  {
    this.load();
  }

  load()
  {
    this.formGroup = new FormGroup({
      import    :   new FormControl('',Validators.required),
      fileName  :   new FormControl()
    })
  }

  setFileName(files)
  {
    if (files.length === 0) {
      alert("Empty File");
      return;
    }
    let fileToUpload = <File>files[0];

    this.formGroup.patchValue({
      fileName: fileToUpload.name 
    })
  }
  
  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    
    formData.append('file', fileToUpload, fileToUpload.name);
    this.http.post('http://localhost:64263/api/Student/postcsv', formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
          // this.uploadFinished(event.body);
          // console.log(event.body);          
        }
      });            
  }

  // Your applications input change listener for the CSV File 
  // fileChangeListener($event: any): void {
    fileChangeListener(files: any): void {

    // Select the files from the event
    // const files = $event.srcElement.files;

    if (files.length === 0) {
      return;
    }

    // Parse the file you want to select for the operation along with the configuration
    this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
      .pipe().subscribe((result: Array<any>) => {
        this.csvRecords = result;
        this.checkHeaders();
      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
      });      
  }

  checkHeaders()
  {  
    let csv:PincodeBulkUpload[] = this.csvRecords;
    if(csv != null && csv != undefined)
    {
      if(this.ObjectLength(csv[0]) != 8)
      {   
       this.modal.close();    
       this.notify.warning("Number of columns are incorrect");
       return;
      }

     var hasDuplicates = csv.some(function (currentObject) {
      return csv.hasOwnProperty(currentObject.PinCode)
          || (csv[currentObject.PinCode] = false);
      });

      if(hasDuplicates == true)
      {
        this.modal.close();
        this.notify.warning("Some pincodes are repeated");
      }
      else{
        this.SavePincodeCSV();
      }

    }
    else
    {
      this.modal.close();
      this.notify.warning("File has no data");
      return;
    }
  
  }

  ObjectLength( object ) {
    var length = 0;
    for( var key in object ) {
        if( object.hasOwnProperty(key) ) {
            ++length;
        }
    }
    return length;
  };

  SavePincodeCSV()
  {
    var pincodeList = this.createPayload();
    const sbCreate = this.pinCodeService.saveBulkPincode(pincodeList).pipe(
      tap(() => {
        this.modal.close();
      }),
      catchError((errorMessage) => {
        this.modal.dismiss(errorMessage);
        return of(pincodeList)
      }),
      finalize(() => {
        this.isLoading = false;
      }),
      ).subscribe((response:any) => {
        this.notify.success(response.data);
      });
      this.subscriptions.push(sbCreate);
  }

  createPayload()
  {    
    let data = 
    {
      BulkPincode: []
    }

    for(let pin of this.csvRecords)
    {
      if(pin != undefined)
      {
        let pincod = +pin.PinCode;
        if(pincod == 0)
        {break;}
        let formattedPin = 
        {
          PinCode : +pin.PinCode,
          IsInsert: true,
          District: pin.District,
          State: pin.State,
          City: pin.City,
          Locality: pin.Locality,
          Landmarks: pin.Landmarks,
          PickupBranchId: +pin.PickupBranchId,
          DeliveryBranchId: +pin.DeliveryBranchId 
        }       
      data.BulkPincode.push(formattedPin);
      }
      else
      {
        break;
      }
    }
    return data;
  }

  sampleDownload()
  {
    let SampleDownload = {
    };

    let heading = ['country','state', 'district','city', 'locality','landmarks','deliveryBranch','pickupBranch','pinCode'];
    this.convertToCSV.downloadFile(SampleDownload,'Customer_Pincode_Mapping',heading);
  }

}
