<div class="modal-content"> 
    <div class="modal-header">
      <div class="modal-title h4" id="example-modal-sizes-title-lg">
        <!-- <ng-container *ngIf="country">  -->
          <!-- <ng-container *ngIf="country.countryId">Edit</ng-container>
          <ng-container *ngIf="!country.countryId">Create</ng-container>
          country
          <ng-container *ngIf="country.countryId">'{{country.country_Name}}'</ng-container> -->
          <!-- <ng-container *ngIf="id">Edit</ng-container>
          <ng-container *ngIf="!id">Create</ng-container>
          country
          <ng-container *ngIf="country.countryId">'{{country.country_Name}}'</ng-container>
        </ng-container> -->
        <ng-container>Add City</ng-container>
      </div>
    </div> 
    <div class="overlay overlay-block cursor-default modal-body" style="min-height: 404px;">
        <ng-container>
            <form action="#" class="form form-label-right" [formGroup]="formGroup">
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>Select Country</label>
                        <select class="form-control form-control-lg form-control-solid" name="countryName"
                        [class.is-invalid]="isControlInvalid('countryName')" formControlName="countryName">
                            <option value="INDIA">INDIA</option>
                        </select>                       
                    </div>  
                    <div class="col-lg-6">
                        <label>Select Zone</label>
                        <select class="form-control form-control-lg form-control-solid" name="zoneName"
                        [class.is-invalid]="isControlInvalid('zoneName')" formControlName="zoneName">
                            <option value="INDIA">EAST</option>
                            <option value="INDIA">NORTH</option>
                            <option value="INDIA">NORTH-EAST</option>
                            <option value="INDIA">SOUTH</option>
                            <option value="INDIA">WEST</option>
                        </select>                       
                    </div>    
                </div> 
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>Select State</label>
                        <select class="form-control form-control-lg form-control-solid" name="stateName"
                        [class.is-invalid]="isControlInvalid('stateName')" formControlName="stateName">
                            <option value="INDIA">HARYANA</option>
                            <option value="INDIA">DELHI</option>
                            <option value="INDIA">HIMACHAL PRADESH</option>
                            <option value="INDIA">PUNJAB</option>
                            <option value="INDIA">RAJASTHAN</option>
                        </select>                       
                    </div>  
                    <div class="col-lg-6">
                        <label>Select City Tier</label>
                        <select class="form-control form-control-lg form-control-solid" name="cityTier"
                        [class.is-invalid]="isControlInvalid('cityTier')" formControlName="cityTier">
                            <option value="INDIA">1</option>
                            <option value="INDIA">2</option>
                            <option value="INDIA">3</option>
                            <option value="INDIA">4</option>
                            <option value="INDIA">5</option>
                        </select>                       
                    </div>            
                </div> 
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label class="form-label">City Name </label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="cityName"
                        placeholder="Enter City Name" autocomplete="off" [class.is-invalid]="isControlInvalid('cityName')"
                        [class.is-valid]="isControlValid('cityName')" formControlName="cityName" />
                        <div class="valid-feedback" *ngIf="isControlValid('cityName')">City Name was entered correct</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('required', 'cityName')">City Name is required</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'cityName')">City Name should have at
                          least 3 symbols</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'cityName')">City Name should have
                          maximum 100 symbols</div>
                    </div>         
                    <div class="col-lg-6">
                        <label class="form-label">City Code </label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="cityCode"
                        placeholder="Enter City Name" autocomplete="off" [class.is-invalid]="isControlInvalid('cityCode')"
                        [class.is-valid]="isControlValid('cityCode')" formControlName="cityCode" />
                        <div class="valid-feedback" *ngIf="isControlValid('cityCode')">City Name was entered correct</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('required', 'cityCode')">City Name is required</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'cityCode')">City Name should have at
                          least 3 symbols</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'cityCode')">City Name should have
                          maximum 100 symbols</div>
                    </div>           
                </div> 
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label class="form-label">Metro </label>
                        <mat-radio-group aria-label="Select an option" formControlName="metro"><br>
                          <mat-radio-button  value="1" class="form-control-sm form-control-solid" name="metro" >
                              Yes
                          </mat-radio-button>
                          <mat-radio-button value="2" class="form-control-sm form-control-solid" name="metro" >
                              No
                          </mat-radio-button>                            
                        </mat-radio-group>
                    </div>         
                    <div class="col-lg-6">
                        <label class="form-label">Airport</label>
                        <mat-radio-group aria-label="Select an option" formControlName="airport"><br>
                          <mat-radio-button  value="1" class="form-control-sm form-control-solid" name="airport" >
                              Yes
                          </mat-radio-button>
                          <mat-radio-button value="2" class="form-control-sm form-control-solid" name="airport" >
                              No
                          </mat-radio-button>                            
                        </mat-radio-group>
                    </div>           
                </div>        
            </form>                
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-elevate mr-2" (click)="modal.dismiss()">Cancel</button>    
          <button type="submit" class="btn btn-primary btn-elevate" (click)="save()"
            [disabled]="formGroup.invalid">Save</button>
    </div>
</div>


