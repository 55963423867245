import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Country } from '../../../_models/country.model';
import { first, catchError } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { CountryService } from '../../../_services/country.service';

const EMPTY_COUNTRY: Country = {
  id: undefined,
  countryId: undefined,
  country_Name: '',
  country_Code: '',
  currency: '',
  isD_Code: '',
  pin_code_Length: '',
  status: undefined,
  isActive: undefined,
  created_at: '',
  userId: undefined
};

@Component({
  selector: 'app-edit-country-modal',
  templateUrl: './edit-country-modal.component.html',
  styleUrls: ['./edit-country-modal.component.scss']
})
export class EditCountryModalComponent implements OnInit {
  @Input() id: number;
  public Id;
  isLoading$;
  // formGroup: FormGroup;
  country: Country;
  // public formGroup;
  formGroup: FormGroup;
  private subscriptions: Subscription[] = [];
  constructor( private countryService: CountryService, 
    private fb: FormBuilder, public modal: NgbActiveModal) { } 

  ngOnInit(): void {  
    this.loadCountry();
  }

  loadCountry() 
  {
    if(!this.id)
    {    
      // console.log("called inside load country");  
      this.country = EMPTY_COUNTRY;
      this.loadForm();
    }    
    else
    {
      // console.log("called inside load country else");
      this.country = {
        id: 1,
        countryId: 1,
        country_Name: 'INDIA',
        country_Code: '',
        currency: '',
        isD_Code: '',
        pin_code_Length: '',
        status: undefined,
        isActive: undefined,
        created_at: '',
        userId: undefined
      };
      // const sb = this.countryService.getItemById(this.id).pipe( 
      //   first(),
      //   catchError((errorMessage) => {
      //     this.modal.dismiss(errorMessage);
      //     return of(EMPTY_COUNTRY);
      //   })
      // ).subscribe((country: Country) => {
      //   this.country = country;
      //   this.loadForm();
      // });
      // this.subscriptions.push(sb);
      // this.country.countryId = this.id;      
      this.Id = this.id;
      // console.log("id is "+this.id);
      this.loadForm();
    }
  }

  loadForm()
  {
     this.formGroup = new FormGroup({
      countryName     :   new FormControl('',Validators.required),
      countryCode     :   new FormControl('',Validators.required),
      currency        :   new FormControl('',Validators.required),   
      isdCode         :   new FormControl('',Validators.required),
      pinCodeLength   :   new FormControl('',Validators.required)
    });

    // this.formGroup = this.fb.group({
    //   countryId: [this.country.countryId, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
    //   lastName: [this.country.country_Name, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
    //   email: [this.country.country_Code, Validators.compose([Validators.required, Validators.email])],
    //   dob: [this.country.currency, Validators.compose([Validators.nullValidator])],
    //   userName: [this.country.isD_Code, Validators.compose([Validators.required])],
    //   gender: [this.country.pin_code_Length, Validators.compose([Validators.required])],
    //   ipAddress: [this.country.status]
    // });

  }

  save() {

  }

    // helpers for View
    isControlValid(controlName: string): boolean {
      const control = this.formGroup.controls[controlName];
      return control.valid && (control.dirty || control.touched);
    }
  
    isControlInvalid(controlName: string): boolean {
      const control = this.formGroup.controls[controlName];
      return control.invalid && (control.dirty || control.touched);
    }
  
    controlHasError(validation, controlName): boolean {
      const control = this.formGroup.controls[controlName];
      return control.hasError(validation) && (control.dirty || control.touched);
    }
  
    isControlTouched(controlName): boolean {
      const control = this.formGroup.controls[controlName];
      return control.dirty || control.touched;
    }

}
