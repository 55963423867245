import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationUrlServiceService {

  API_URL = environment.apiUrl;
  Pincode: string = "Pincode/";
  Location: string = "Location/";

  constructor() { }

  getAllPincode: string = this.API_URL + this.Pincode + "GetPincodeCode";
  getPincodeId: string = this.API_URL + this.Pincode + "GetPincodeCodeById";
  DeletePincode: string = this.API_URL + this.Pincode + "DeletePincode";
  getStates: string = this.API_URL + this.Location + "GetStates";
  getDistrict: string = this.API_URL + this.Location + "GetDistricts";
  getCity: string = this.API_URL + this.Location + "GetCity";
  getLocality: string = this.API_URL + this.Location + "GetLocality";
  postPincode: string = this.API_URL + this.Pincode + "PostPincode";
  GetBranch: string = this.API_URL + this.Pincode + "GetBranch";  
}
