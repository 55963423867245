import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild  {

  constructor(private authService: AuthService, private _router: Router,) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;   
    const role = this.authService.getAuthFromLocalStorage();

    if (currentUser) {      
      // logged in so return true
      let basePath = state.url.split('/').slice(1);
      if(basePath[0].toString() == "Admin" && role.roleName == "admin") 
      {
        return true;
      }
      else if(basePath[0].toString() == "User" && role.roleName == "branchuser")
      {        
        return true;
      }
      else if(basePath[0].toString() == "Client" && role.roleName == "Client")
      {        
        return true;
      }
      this._router.navigate(["/"]);
      this.authService.logout();
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.authService.logout();
    return false;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
    const role = this.authService.getAuthFromLocalStorage();

    if(role.accessToken && (role.roleName != null) && (role.roleName != undefined))
    {
      let basePath = state.url.split('/').slice(1);
      if(basePath[0].toString() == "Admin" && role.roleName == "admin") 
      {
        return true;
      }
      else if(basePath[0].toString() == "User" && role.roleName == "branchuser")
      {
        return true;
      }
      else if(basePath[0].toString() == "Client" && role.roleName == "Client")
      {        
        return true;
      }
      else
      {
        this.authService.logout();
        return false;
      }
      // return true;
    }
    else
    {
      this.authService.logout();
    }

    return true;
  }
}
