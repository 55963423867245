import { Injectable, Inject, OnDestroy } from '@angular/core';
import { ITableState, PaginatorState, SortState, GroupingState, TableResponseModel, TableService } from 'src/app/_metronic/shared/crud-table';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map, exhaustMap } from 'rxjs/operators';
import { baseFilter } from 'src/app/_fake/fake-helpers/http-extenstions';
import { Country } from '../_models/country.model';

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined
};

@Injectable({
  providedIn: 'root'
})
export class CountryService extends TableService<Country> implements OnDestroy { 
  // API_URL = `${environment.apiUrl}/customers`; 
  API_URL = "http://localhost:53347/api/Home/getCountries";
  // API_URL = "";

  constructor(@Inject(HttpClient) http) {
    super(http);
  }

    // READ
    find(tableState: ITableState): Observable<TableResponseModel<Country>> {             
      return this.http.get<Country[]>(this.API_URL).pipe(
        map((response) => {
          const filteredResult = baseFilter(response, tableState); 
          const result: TableResponseModel<Country> = {
            items: filteredResult.items,
            total: filteredResult.total 
          };   
          console.log(result)       
          return result;
        })
      );      
    } 
  
    // deleteItems(ids: number[] = []): Observable<any> {
    //   const tasks$ = [];
    //   ids.forEach(id => {
    //     tasks$.push(this.delete(id));
    //   });
    //   return forkJoin(tasks$);
    // }
  
    // updateStatusForItems(ids: number[], status: number): Observable<any> {
    //   return this.http.get<Country[]>(this.API_URL).pipe(
    //     map((customers: Country[]) => {
    //       return customers.filter(c => ids.indexOf(c.id) > -1).map(c => {
    //         c.status = status;
    //         return c;
    //       });
    //     }),
    //     exhaustMap((customers: Country[]) => {
    //       const tasks$ = [];
    //       customers.forEach(customer => {
    //         tasks$.push(this.update(customer));
    //       });
    //       return forkJoin(tasks$);
    //     })
    //   );
    // }
  
    ngOnDestroy() 
    {
      this.subscriptions.forEach(sb => sb.unsubscribe());
    }

}
