export class AuthModel { 
  accessToken: string;
  refreshToken: string;
  expiresIn: Date;
  roleName: string;

  setAuth(auth: any) {
    this.accessToken = auth.accessToken;
    this.refreshToken = auth.refreshToken; 
    this.expiresIn = auth.expiresIn;
    this.roleName = auth.roleName;
  }

  // accessToken: string;
  // userData: Object;
  // setAuth(auth: any) {
  //   this.accessToken = auth.accessToken;
  //   this.userData = auth.userData;
  // }

}
