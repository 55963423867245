import { Component, OnInit, Input } from '@angular/core';
import { PinCode } from '../../../_models/pinCode.model';
import { PincodeService } from '../../../_services/pincode.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subscription, of, Observable } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { first, catchError, tap, finalize, startWith, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NotificationService } from 'src/app/_services/notification.service';

const EMPTY_PIN: PinCode = {
  id: undefined,
  PinCode: null,
  District: '',
  State: '',
  StateId: null,
  City: '',
  Locality: '',
  Landmarks: '',
  Country: '',
  PickupBranchId: null,
  PickupBranch: '',
  DeliveryBranchId: null,
  DeliveryBranch:'',
  IsActive:null,
  CreatedAt:null,
};

@Component({
  selector: 'app-edit-pin-code-v2-modal',
  templateUrl: './edit-pin-code-v2-modal.component.html',
  styleUrls: ['./edit-pin-code-v2-modal.component.scss']
})
export class EditPinCodeV2ModalComponent implements OnInit {
  @Input() id: number;
  isLoading$;
  pincode: PinCode;
  formGroup: FormGroup;
  private subscriptions: Subscription[] = [];

  public disable = false;
  pinCode: PinCode;
  options: string[] = ['NA'];
  filteredOptions: Observable<string[]>;
  private selectedState = null;

  constructor
  ( 
    public pinCodeService: PincodeService,
    private fb: FormBuilder, 
    public modal: NgbActiveModal,
    private notify: NotificationService
  ) { }

  ngOnInit(): void { 
    // console.log(this.selectedState);
    this.isLoading$ = this.pinCodeService.isLoading$;
    this.loadCustomer();
    this.searchForm();
    if(!this.id)
    {
      this.filteredOptions = this.formGroup.get('cityName').valueChanges.pipe(
        startWith(''),
        map((value:any) => this._filter(value))
      );
    }
  }

   // convenience getter for easy access to form fields
   get f() {
    return this.formGroup.controls;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.pinCodeService.city$.subscribe((u) => {
      this.options = u;
    })
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  loadCustomer() {    
    this.pinCodeService.getStates();   
    this.pinCodeService.getBranchById(null);
    if (!this.id) {
      //console.log("called load customer edit without id");
      this.disable = false;
      this.pincode = EMPTY_PIN;
      this.loadForm();
    } else {
      //console.log("called load customer edit");
      this.disable = true;
      const sb = this.pinCodeService.getPincodeById(this.id).pipe( 
        first(),
        catchError((errorMessage) => {
          this.modal.dismiss(errorMessage);
          return of(EMPTY_PIN);
        })
      ).subscribe((pincode: PinCode) => {
        this.pincode = pincode;
        // console.log(this.pincode);
        this.afterStateSelect(this.pincode.StateId);
        this.loadForm();
      });
      this.subscriptions.push(sb);
    }
  }

  afterStateSelect(stateID)
  { 
    this.selectedState = stateID; 
    // console.log("selected State is",this.selectedState);
    if((stateID != null) && (stateID != undefined))
    {    
      this.options.length = 0;   
      this.options = [];

      var stateid:string = "" + stateID;
      this.pinCodeService.getDistrictById(stateid);  
      this.pinCodeService.getCityById(stateid);  
      this.pinCodeService.getLocalityById(stateid); 

      this.pinCodeService.city$.subscribe((data:any) => {
      if(data.length > 0)
      {
        // this.pinCodeService.getCityById(stateid);
        let i = 0;
        for(i; i<data.length; i++)
        {
          // console.log(i,data[i].cityName);
          this.options.push(data[i].cityName);
        }        
      }      
      })   
    }  
  }

  loadForm() {         
    this.formGroup = this.fb.group({
      stateName: [this.pincode.StateId, Validators.compose([Validators.required])],
      cityName: [this.pincode.City, Validators.compose([Validators.pattern(/^[a-z A-Z_-]*$/)]) ],
      districName: [this.pincode.District, Validators.compose([Validators.required])],
      localityName: [this.pincode.Locality, Validators.compose([Validators.required,Validators.pattern(/^[a-z A-Z_-]*$/)])],
      pickupBranch: [this.pincode.PickupBranchId, Validators.compose([Validators.required])],
      destination: [this.pincode.DeliveryBranchId, Validators.compose([Validators.required])],
      // pinCode: [this.pincode.pinCode, Validators.compose([Validators.required,Validators.minLength(3), Validators.maxLength(6)])],      
      pinCode: [this.pincode.PinCode,  Validators.compose([Validators.required, Validators.minLength(1),Validators.min(100000), Validators.maxLength(6),Validators.max(999999)])],
    });
  }

  save() 
  {
    if(this.formGroup.valid)
    {
      let city;
      if((this.formGroup.value.cityName == "") || (this.formGroup.value.cityName == null) || (this.formGroup.value.cityName == undefined))
      {
        city =  "NA";
      }
      else
      {
        city = this.formGroup.value.cityName;
        city = city.toUpperCase();
      }
      let locality = this.formGroup.value.localityName;
      locality = locality.toUpperCase();
      let pincode = {
      "BulkPincode": [
        {
          "PinCode": this.formGroup.value.pinCode,
          "IsInsert":true,
          "District":this.formGroup.value.districName,
          "State":this.formGroup.value.stateName,
          // "City":this.formGroup.value.cityName,
          "City":city,
          "Locality":locality,
          "Landmarks":"LM",
          "PickupBranchId":this.formGroup.value.pickupBranch,
          "DeliveryBranchId":this.formGroup.value.destination
        }
      ]
    };

    if(this.id)
    {
      //console.log("called in case of edit");
      pincode.BulkPincode[0].IsInsert = false;
    }
    // console.log(pincode);return;
    const sbCreate = this.pinCodeService.savePincode(pincode).pipe( 
      tap(() => {
        this.modal.close();
      }),
      catchError((errorMessage) => {
        this.modal.dismiss(errorMessage);
        return of(this.pinCode);
      }),
      finalize(() => {
        // this.isLoading = false;
      }),
    ).subscribe((response:any)=>{
      //console.log(response);
      this.notify.success(response.data)
    });
    this.subscriptions.push(sbCreate);
    this.pinCodeService.fetch();
    }
    else
    {
      this.notify.warning("Please fill all mandatory fields");
    }
  }

  searchForm() {
    if(!this.id)
    {
      const searchEvent = this.formGroup.controls.cityName.valueChanges
      .pipe(
        /*
      The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator,
      we are limiting the amount of server requests emitted to a maximum of one every 150ms
      */
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe((val) => this.search(val));
    this.subscriptions.push(searchEvent); 
    // console.log(searchEvent); 
    }   
  }

  search(searchTerm: string) {
    if(this.selectedState != null)
    {
    searchTerm = searchTerm.toLocaleLowerCase();
    // console.log(searchTerm);
    this.pinCodeService.patchStateCity({ searchTerm });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  // helpers for View
  isControlValid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  controlHasError(validation, controlName): boolean {
    const control = this.formGroup.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }

  isControlTouched(controlName): boolean {
    const control = this.formGroup.controls[controlName];
    return control.dirty || control.touched;
  }

}
