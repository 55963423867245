<div class="modal-content"> 
    <div class="modal-header">
      <div class="modal-title h4" id="example-modal-sizes-title-lg">
        <!-- <ng-container *ngIf="country">  -->
          <!-- <ng-container *ngIf="country.countryId">Edit</ng-container>
          <ng-container *ngIf="!country.countryId">Create</ng-container>
          country
          <ng-container *ngIf="country.countryId">'{{country.country_Name}}'</ng-container> -->
          <!-- <ng-container *ngIf="id">Edit</ng-container>
          <ng-container *ngIf="!id">Create</ng-container>
          country
          <ng-container *ngIf="country.countryId">'{{country.country_Name}}'</ng-container>
        </ng-container> -->
        <ng-container>Add State</ng-container>
      </div>
    </div> 
    <div class="overlay overlay-block cursor-default modal-body" style="min-height: 404px;">
        <ng-container>
            <form action="#" class="form form-label-right" [formGroup]="formGroup">
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>Select Country</label>
                        <select class="form-control form-control-lg form-control-solid" name="countryName"
                        [class.is-invalid]="isControlInvalid('countryName')" formControlName="countryName">
                            <option value="INDIA">INDIA</option>
                        </select>                       
                    </div>  
                    <div class="col-lg-6">
                        <label>Select Zone</label>
                        <select class="form-control form-control-lg form-control-solid" name="zoneName"
                        [class.is-invalid]="isControlInvalid('zoneName')" formControlName="zoneName">
                            <option value="INDIA">EAST</option>
                            <option value="INDIA">NORTH</option>
                            <option value="INDIA">NORTH-EAST</option>
                            <option value="INDIA">SOUTH</option>
                            <option value="INDIA">WEST</option>
                        </select>                       
                    </div>    
                </div> 
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label class="form-label">State Code</label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="stateCode"
                        placeholder="Enter state code" autocomplete="off" [class.is-invalid]="isControlInvalid('stateCode')"
                        [class.is-valid]="isControlValid('stateCode')" formControlName="stateCode" />
                        <div class="valid-feedback" *ngIf="isControlValid('stateCode')">State code was entered correct</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('required', 'stateCode')">State code is required</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'stateCode')">State code should have at
                          least 3 symbols</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'stateCode')">State code should have
                          maximum 100 symbols</div>
                    </div> 
                    <div class="col-lg-6">
                        <label class="form-label">State Name </label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="stateName"
                        placeholder="Enter State Name" autocomplete="off" [class.is-invalid]="isControlInvalid('stateName')"
                        [class.is-valid]="isControlValid('stateName')" formControlName="stateName" />
                        <div class="valid-feedback" *ngIf="isControlValid('stateName')">State Name was entered correct</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('required', 'stateName')">State Name is required</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'stateName')">State Name should have at
                          least 3 symbols</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'stateName')">State Name should have
                          maximum 100 symbols</div>
                    </div>            
                </div> 
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label class="form-label">State Pin Number </label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="statePinNumber"
                        placeholder="Enter State Pin Number" autocomplete="off" [class.is-invalid]="isControlInvalid('statePinNumber')"
                        [class.is-valid]="isControlValid('statePinNumber')" formControlName="statePinNumber" />
                        <div class="valid-feedback" *ngIf="isControlValid('statePinNumber')">State Pin Number was entered correct</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('required', 'statePinNumber')">State Pin Number is required</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'statePinNumber')">State Pin Number should have at
                          least 3 symbols</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'statePinNumber')">State Pin Number should have
                          maximum 100 symbols</div>
                    </div>         
                    <div class="col-lg-6">
                        <label class="form-label">State Color </label>
                        <input type="color" class="form-control form-control-lg form-control-solid" name="stateColor"
                        placeholder="Enter State Color" autocomplete="off" [class.is-invalid]="isControlInvalid('stateColor')"
                        [class.is-valid]="isControlValid('stateColor')" formControlName="stateColor" />
                        <!-- <div class="valid-feedback" *ngIf="isControlValid('stateColor')">State Color was entered correct</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('required', 'stateColor')">State Color is required</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'stateColor')">State Color should have at
                          least 3 symbols</div>
                        <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'stateColor')">State Color should have
                          maximum 100 symbols</div> -->
                    </div>           
                </div>        
            </form>                
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-elevate mr-2" (click)="modal.dismiss()">Cancel</button>    
          <button type="submit" class="btn btn-primary btn-elevate" (click)="save()"
            [disabled]="formGroup.invalid">Save</button>
    </div>
</div>


