import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route } from '@angular/compiler/src/core';
import { Routes, RouterModule } from '@angular/router';
import { CountryComponent } from './country/country.component';
import { ZoneComponent } from './zone/zone.component';
import { StateComponent } from './state/state.component';
import { CityComponent } from './city/city.component';
import { PinCodeComponent } from './pin-code/pin-code.component';
import { LocalityComponent } from './locality/locality.component';

const routes: Routes = [
  {
    path: '',    
    children: [
      {
        path: 'country',
        component: CountryComponent
      },
      {
        path: 'zone',
        component: ZoneComponent
      },
      {
        path: 'state',
        component: StateComponent
      },
      {
        path: 'city',
        component: CityComponent
      },
      {
        path: 'pincode',
        component: PinCodeComponent
      },
      {
        path: 'locality',
        component: LocalityComponent
      },
      { path: '', redirectTo: 'country', pathMatch: 'full' },
      { path: '**', redirectTo: 'country', pathMatch: 'full' },
    ]
  }
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
})
export class LocationRoutingModule { } 
