import { Injectable, OnDestroy, Inject } from '@angular/core';
import { TableService } from 'src/app/_metronic/shared/crud-table';
import { City } from '../_models/city.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CityService extends TableService<City> implements OnDestroy  {

  constructor(@Inject(HttpClient) http) 
  {
    super(http);
  } 

  ngOnDestroy() 
  {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
  
}
