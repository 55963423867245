import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  //#region Old DMS domain name 
  DMS_Old_API_URL = environment.dmsOldapiUrl;
  //#endregion

  //#region c# conroller names
  API_URL = environment.apiUrl; 
  Pincode: string = "Pincode/";
  Location: string = "Location/";
  AWB: string = "AWB/";
  customer: string = "customer/";
  ManageBooking: string = "ManageBooking/";
  Applicationuser: string = "applicationuser/";
  UserProfile: string = "UserProfile/";
  RegisterUser: string = "ApplicationUser/";
  DMSOldTracking: string = "tracking/";
  DMSOldTrackingLocal: string = "tracking/";
  DMSOldOrderTrackingLocal: string = "irr/";
  Tracking: string = "tracking/";
  Dashboard: string = "Dashboard/";
  //#endregion

  constructor() { }

  //#region AUTH
  Login: string = this.API_URL + this.Applicationuser + "login"; 
  GetUserDetails: string = this.API_URL + this.UserProfile + "GetUserProfile"; 
  RegisterNewUser: string = this.API_URL + this.RegisterUser + "register";
  ResetPassword: string = this.API_URL + this.Applicationuser + "reset"; 
  //#endregion

  //#region BRANCH
  GetBranch: string = this.API_URL + this.Pincode + "GetBranch"; 
  //#endregion

  //#region AWB  
  // GetAWB: string = this.API_URL + this.AWB + "GetAWB"; 
  GetAWB: string = this.API_URL + this.ManageBooking + "awblist";  
  GenerateAWB: string = this.API_URL + this.AWB + "GenerateAWB";  
  BookOrder: string = this.API_URL + this.ManageBooking + "bookOrder"; 
  GetBooekedOrders: string = this.API_URL + this.ManageBooking + "shipmentlist"; 
  //#endregion

  //#region CUSTOMER BOOKING RULES
  getBookingRules: string = this.API_URL + this.customer + "GetAllBookingRules";  
  postBookingRules: string = this.API_URL + this.customer + "PostBookingRules";
  public getCustomerCode: string = this.API_URL + this.customer + "GetCustomerCode";
  deleteBookingRules: string = this.API_URL + this.customer + "DeleteBookingRule";
  FilteredPincodeLists: string = this.API_URL + this.customer + "FilteredPincodeList";
  InsertCustomerPin: string = this.API_URL + this.customer + "InsertCustomerPin";
  GetCustomerMappedPin: string = this.API_URL + this.customer + "GetCustomerMappedPin";
  DeleteCustomerPincode: string = this.API_URL + this.customer + "DeleteCustomerPincode";
  UpdateCustomerPin: string = this.API_URL + this.customer + "UpdateCustomerPin"; 
  //#endregion
  GetShipmentVerdis: string = this.API_URL + this.Tracking + "getHolisolOrders"; 
  GetShipmentMISReport: string = this.API_URL + this.Tracking + "misReport"; 
  GetShipmentMISReportV2: string = this.API_URL + this.Tracking + "misShipmentReport"; 
  //#region Cod Report --Added By KK
  GetShipmentcodReport: string = this.API_URL + this.Tracking + "codReport"; 
  GetShipmentcodReportV2: string = this.API_URL + this.Tracking + "codShipmentReport"; 
  //#endregion
  //#region TRAKING V2
  getv2AWBTracking:string = this.API_URL + this.Tracking + "trackOrder";
  getv2AWBTrackingv1:string = this.API_URL + this.Tracking + "TrackMyOrder";
  //#endregion
  
  //#region DASHBOARD 
    GetGraphCurrentStatus: string = this.API_URL + this.Dashboard + "GetCurrentStatus";
    GetBooked: string = this.API_URL + this.Dashboard + "GetBooked";
    GetNotPicked: string = this.API_URL + this.Dashboard + "GetNotPicked";
    GetInTransit: string = this.API_URL + this.Dashboard + "GetInTransit";
    GetOutForDelivery: string = this.API_URL + this.Dashboard + "GetOFD";
    GetDelivered: string = this.API_URL + this.Dashboard + "GetDelivered";
    GetUnDelivered: string = this.API_URL + this.Dashboard + "GetUnDelivered";
    GetRTOMarked: string = this.API_URL + this.Dashboard + "GetRTOMarked";
    GetReturned: string = this.API_URL + this.Dashboard + "GetReturned";
  //#endregion
  //#region DMS OLD API 
    //#region TRACKING
      // getTracking: string = this.OldDMS_API + this.DMSOldTracking + "GetShipmentDetails?awbNo=HL50000000005,HL50000000009";
      getoldAWBTracking:string = this.DMS_Old_API_URL + this.DMSOldTrackingLocal + "GetShipmentDetails";
      getoldAWBhistory:string = this.DMS_Old_API_URL + this.DMSOldOrderTrackingLocal + "SearchMultipleAwb";
    //#endregion
  //#endregion

}
