import { Injectable, OnDestroy, Inject } from '@angular/core';
import { PinCode } from '../_models/pinCode.model';
import { TableService, ITableState, TableResponseModel, BaseModel, PaginatorState, SortState, GroupingState } from 'src/app/_metronic/shared/crud-table';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { baseFilter } from 'src/app/_fake/fake-helpers/http-extenstions';
import { map, catchError, finalize, tap } from 'rxjs/operators';
import { LocationUrlServiceService } from './location-url-service.service';
import { AuthService } from '../../auth';
import { UserWiseAccessService } from 'src/app/_services/user-wise-access.service';

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined
};

@Injectable({
  providedIn: 'root'
})
export class PincodeService extends TableService<PinCode> implements OnDestroy {

  public _states$ = new BehaviorSubject<any[]>([]);
  public _district$ = new BehaviorSubject<any[]>([]);
  public _city$ = new BehaviorSubject<any[]>([]);
  public _locality$ = new BehaviorSubject<any[]>([]);
  public _branch$ = new BehaviorSubject<any[]>([]);
  public _allItems$ = new BehaviorSubject<any[]>([]);
  public _tableStateCity$ = new BehaviorSubject<ITableState>(DEFAULT_STATE);

  //#region GETTERS
  get states$() {
    return this._states$.asObservable();
  }
  get district$() {
    return this._district$.asObservable();
  }
  get city$() {
    return this._city$.asObservable();
  }
  get locality$() {
    return this._locality$.asObservable();
  }
  get branch$() {
    return this._branch$.asObservable();
  }
  get allItems$() {
    return this._allItems$.asObservable();
  }
  //#endregion

  selectedState = null;
  similarStateSelected:boolean;

  constructor
  (
    @Inject(HttpClient) http, 
    private _url: LocationUrlServiceService,
    private auth: AuthService,
    private access: UserWiseAccessService
  ) 
  {
    super(http);    
  } 

  //To add parameters to url
  createGetParams(term:any, name:any)
  {
    var term = term.trim();
    const options = term ?
   { params: new HttpParams().set(name, term) } : {};
   return options;
  }  
  // READ 
  find(tableState: ITableState): Observable<TableResponseModel<PinCode>> {   
    var data;
      data = 
      {
        "pinCode":0,
        "userId":this.access.userId,
        "selectParam": this.access.userParam()
      };
      // console.log("data is ",data);
      return this.http.post<PinCode[]>(this._url.getPincodeId, data).pipe(
      map((response:any) => {   
        const filteredResult = baseFilter(response.data, tableState);  
        this._allItems$.next(filteredResult.allitems);
        const result: TableResponseModel<PinCode> = {
          items: filteredResult.items,
          total: filteredResult.total 
        };   
        return result;
      })
    );      
  } 

  getStates()
  {
    let value;
    this._states$.subscribe((d:any) => {
      value = d;
    })
    if(value == 0)
    {
      return this.http.get<any[]>(this._url.getStates).subscribe((Response:any) => {
        // console.log("states",Response);
        this._states$.next(Response.data);
    });
    }
  }

  getCityById(term:string)
  {  
    if( this.similarStateSelected == false )
    {  
      return this.http.get<any[]>(this._url.getCity, this.createGetParams(term,"id")).subscribe((Response:any) => {
          // console.log("city",Response);
          this._city$.next(Response.data);
      });
    }
  }

  getCityByIdSearch(tableState: ITableState): Observable<TableResponseModel<PinCode>> {     
    // if( this.similarStateSelected == false )
    // {  
      return this.http.get<any[]>(this._url.getCity, this.createGetParams(this.selectedState,"id")).pipe(
      map((response:any) => { 
        const filteredResult = baseFilter(response.data, tableState);  
        this._city$.next(filteredResult.items);
        const result: TableResponseModel<any> = {
          items: filteredResult.items,
          total: filteredResult.total 
        };   
        // console.log("result is",result)    
        return result;
      })
      ); 
    // }    
  } 

  public patchStateCity(patch: Partial<ITableState>) {    
    this.patchStateWithoutFetchCity(patch);
    this.fetchCity();
  }

  public patchStateWithoutFetchCity(patch: Partial<ITableState>) {  
    const newState = Object.assign(this._tableStateCity$.value, patch);    
    this._tableStateCity$.next(newState);
  }

  public fetchCity() {
    // this._isLoading$.next(true);
    this._errorMessage.next('');
    // if( this.similarStateSelected == false )
    // {  
    const request = this.getCityByIdSearch(this._tableStateCity$.value)
      .pipe(
        finalize(() => {
          // this._isLoading$.next(false);
        })
      )
      .subscribe();
    // this._subscriptions.push(request);
    // }
  }

  getDistrictById(term:string)
  {    
    if( this.selectedState != term )
    {
      this.similarStateSelected = false;
      this.selectedState = term;
      return this.http.get<any[]>(this._url.getDistrict, this.createGetParams(term,"id")).subscribe((Response:any) => {
        // console.log("district",Response,"id is",term);
        this._district$.next(Response.data);
      });
    }
    else
    { this.similarStateSelected = true }
  }

  getLocalityById(term:string)
  {
    if( this.similarStateSelected == false )
    {  
      return this.http.get<any[]>(this._url.getLocality,this.createGetParams(term,"id")).subscribe((Response:any) => {
          // console.log("locality",Response);
          this._locality$.next(Response.data);
      });
    }
  }

  getBranchById(id:string)
  {  
    let data;
    this._branch$.subscribe((d) => {
      data = d;
    })

    if( data.length == 0 )
    {
       var term = ""+id;
       var term = term.trim();
       const options = term ?
      { params: new HttpParams().set('pin', term) } : {};
       return this.http.get<any[]>(this._url.GetBranch,options).subscribe((Response:any) => {
         //console.log(Response);
           this._branch$.next(Response.data);
       });
    }
  }

  getPincodeById(id)
  {
    this._isLoading$.next(true);
    this._errorMessage.next('');    
    var term = ""+id;
    var term = term.trim();
  //   const options = term ?
  //  { params: new HttpParams().set('pin', term) } : {};
    var options = {    
      "pinCode" : term,
      "userId" : this.access.userId,
      "selectParam" : this.access.userParam()
    }
    // return this.http.get(this._url.getPincodeId,options).pipe( 
      return this.http.post(this._url.getPincodeId,options).pipe( 
      map((config:any) => {        
        return config.data[0]; 
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  savePincode(item:any): Observable<any>
  {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post(this._url.postPincode, item).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('CREATE ITEM', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    )
    //return this.http.post(this._url.postPincode,item).subscribe((result) => {console.log("Result is",result)});
  }

  saveBulkPincode(item:any): Observable<any>
  {
    //console.log("called bulk upload", item);
    return this.http.post(this._url.postPincode,item).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('CREATE ITEM', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  delete(id: any): Observable<any> {
    this._errorMessage.next('');
    this._isLoading$.next(true);

  //   var term = id;
  //   const options = term ?
  //  { params: new HttpParams().set('pincode', term) } : {};    
  //   return this.http.post(this._url.DeletePincode,options).pipe(

    let deleteuri:string = this._url.DeletePincode;
    let cleanUri = deleteuri;
    let editUri = cleanUri + "?pincode="+ id;    
    let data;
    return this.http.post(editUri,data).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  ngOnDestroy() 
  {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  } 
}
