import { Injectable, OnDestroy, Inject } from '@angular/core';
import { TableService } from 'src/app/_metronic/shared/crud-table';
import { HttpClient } from '@angular/common/http';
import { State } from '../_models/state.model';

@Injectable({
  providedIn: 'root'
})
export class StateService extends TableService<State> implements OnDestroy
{
  constructor(@Inject(HttpClient) http) 
  {
    super(http); 
  }
  
  ngOnDestroy() 
  {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
}
