import { Injectable } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment'; 
import { AuthModel } from '../../_models/auth.model';
import { map } from 'rxjs/operators';
import { UrlService } from 'src/app/_services/url.service';

// const API_USERS_URL = `${environment.apiUrl}/users`;
const API_USERS_URL = "https://localhost:44329/api/applicationuser/login";

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  private userDetails:any;
  constructor(private http: HttpClient, private _url: UrlService) { } 

  // public methods
  login(email: string, password: string): Observable<any> {
    // return this.http.post<AuthModel>(API_USERS_URL,   { email, password });
    let data = { 
      "UserName": email,
     "Password": password
    }
       this.userDetails = this.http.post(this._url.Login,data).pipe(
      map((result:UserModel) => {   
        // console.log("result",result) 
        const auth = new AuthModel();        
        auth.accessToken = result.token;
        auth.refreshToken = "refresh-token";
        auth.expiresIn = new Date(Date.now() + 1000); //100 * 24 * 60 * 
        auth.roleName = result.userData.RoleName;
        // console.log("auth",auth) 
        return auth; 
      })      
    );
    return this.userDetails;
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(this._url.RegisterNewUser, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

    getUserByToken(token): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
      return this.http.post<any>(this._url.GetUserDetails, {
      headers: httpHeaders,
    });      
  }
}
