import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CityService } from '../_services/city.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditCityModalComponent } from './components/edit-city-modal/edit-city-modal.component';

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss']
})
export class CityComponent implements OnInit {
  // paginator: PaginatorState;
  // sorting: SortState;
  // grouping: GroupingState;
  // isLoading: boolean;
  filterGroup: FormGroup;
  searchGroup: FormGroup;
  private subscriptions: Subscription[] = []; 
  public items2$:[];

  constructor
  (
    private fb: FormBuilder,
    private modalService: NgbModal,
    public cityService: CityService
  ) { }

  ngOnInit(): void 
  {
    this.filterForm();
    this.searchForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  // filtration
  filterForm() 
  {
     this.filterGroup = this.fb.group({
       status: [''],
       type: [''],
       searchTerm: [''],
     });
     this.subscriptions.push(
       this.filterGroup.controls.status.valueChanges.subscribe(() => 
         this.filter()
       )
     );
     this.subscriptions.push(
       this.filterGroup.controls.type.valueChanges.subscribe(() => this.filter())
     );
  }

    filter() 
    {
      // const filter = {};
      // const status = this.filterGroup.get('status').value;
      // if (status) {
      //   filter['status'] = status;
      // }
  
      // const type = this.filterGroup.get('type').value;
      // if (type) {
      //   filter['type'] = type;
      // }
      // this.zoneService.patchState({ filter });
    }

      // search
  searchForm() {
    this.searchGroup = this.fb.group({
      searchTerm: [''],
    });
    const searchEvent = this.searchGroup.controls.searchTerm.valueChanges
      .pipe(
        /*
      The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator,
      we are limiting the amount of server requests emitted to a maximum of one every 150ms
      */
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe((val) => this.search(val));
    this.subscriptions.push(searchEvent);
  }

  search(searchTerm: string) {
    // this.zoneService.patchState({ searchTerm });
  }

  // form actions
  create() {
    this.edit(undefined);
  }

  edit(id: number) {
    // console.log("id is "+ id);
    const modalRef = this.modalService.open(EditCityModalComponent, { size: 'xl' }); 
    modalRef.componentInstance.id = id;
    modalRef.result.then(() =>
      this.cityService.fetch(),
      () => { }
    );
  }

}
