// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v723dmsV2',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  // apiUrl: 'api'
  // apiUrl: 'http://localhost:53347/api/Home',
  // apiUrl: 'https://localhost:5001/api/',
  //  apiUrl: 'http://v2.holisoldelivery.com/api/',
  apiUrl: 'http://demo.holisoldelivery.com/api/', 
  dmsOldapiUrl: 'http://test.holisoldelivery.com/api/',
  // apiUrl: 'http://demo.holisoldelivery.com/api/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
